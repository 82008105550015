import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { FaPencilAlt } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdFilterList } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaFilter } from "react-icons/fa6";
import { BiCaretDown, BiCaretUp, BiCheck } from "react-icons/bi";
import Swal from "sweetalert2";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Modal,
  Table,
} from "reactstrap";
import ApiConfig from "../../../api/ApiConfig";
import {
  multipartPostCallWithErrorResponseAxios,
  multipartPatchCallWithErrorResponseAxios,
  simpleGetCallWithTokenAxios,
  DeleteCallWithErrorResponseAxios,
} from "../../../api/ApiServices";
import ToastMsg from "../../../utils/ToastMsg";
import Loader from "../../../layouts/loader/Loader";
import CommonDataTable from "../../../commonComponents/CommonDataTable";
/* eslint-disable camelcase */
const ManagePreReq = (args) => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("S£"); // Default sorting by university
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting in ascending order
  const [courseProgramStatuses, setCourseProgramStatuses] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [courseProgramName, setProgramName] = useState([]);
  const [courseProgramData, setCourseProgramData] = useState({});
  const [loading, setLoading] = useState(false);
  const [ProgramCourse, setProgramCourse] = useState([]);
  const [ProgramPrerequisite, setProgramPrerequisite] = useState([]);
  const [courseProgramNames, setCourseProgramNames] = useState({});
  const [coursePlanNames, setCoursePlanNames] = useState({});
  const [switchStates, setSwitchStates] = useState({});
  const [dataStatus, setdataStatus] = useState("active");
  const [filterData, setFilterData] = useState({ program: "", plan: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addProgramCourseStatusData, setAddProgramCourseStatusData] = useState({
    program_name: "",
    course_plan: "",
    program_course: "",
    prerequisite_course: "",
    is_active: false,
  });
  const [editProgramCourseStatusData, setEditProgramCourseStatusData] =
    useState({
      id: null,
      program_name: "",
      course_plan: "",
      program_course: "",
      prerequisite_course: "",
      is_active: false,
    });
  const toggleSortOrder = (columnName) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };
  const successToggle = () => setSuccess(!success);
  const addToggle = () => setAddModal(!addModal);

  const editToggle = (status) => {
    setEditModal(!editModal);
    if (status) {
      setEditProgramCourseStatusData({
        id: status?.id,
        program_name: status?.program,
        course_plan: status?.course_plan,
        program_course: status?.program_course,
        prerequisite_course: Array.isArray(status?.prerequisite_course)
          ? status?.prerequisite_course
          : [status?.prerequisite_course],
        is_active: status?.is_active,
      });
    }
  };
  const sortProgramCourseStatuses = (statuses) => {
    if (!Array.isArray(statuses)) {
      return [statuses];
    }
    const sortedStatuses = [...statuses].sort((a, b) => {
      const getFieldValue = (status, field) => {
        switch (field) {
          case "Program":
            return status?.program_name?.toUpperCase() ?? "";
          case "Plan Name":
            return status?.course_plan_name?.toUpperCase() ?? "";
          case "Courses":
            return status?.program_course_name?.toUpperCase() ?? "";
          case "Prerequisite Course":
            return status?.prerequisite_course?.toUpperCase() ?? "";
          case "Is Active":
            return status.is_active ? "ACTIVE" : "INACTIVE";
          default:
            return "";
        }
      };

      const valueA = getFieldValue(a, sortBy);
      const valueB = getFieldValue(b, sortBy);

      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
      return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
    });

    return sortedStatuses;
  };
  const getProgramList = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.PROGRAMLIST)
      .then((res) => {
        const courseProgramNameData = res?.data?.reduce(
          (acc, CourseProgram) => {
            acc[CourseProgram?.id] = CourseProgram?.name;
            return acc;
          },
          {}
        );
        setProgramName(courseProgramNameData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getProgramCoursePlan = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.PROGRAM_COURSE_PLAN)
      .then((res) => {
        const sortedStatuses = sortProgramCourseStatuses(res?.data?.results);
        setCourseProgramStatuses(sortedStatuses);
        const programCoursePlans = sortedStatuses?.reduce((acc, CourseType) => {
          if (!Object.values(acc).includes(CourseType?.name)) {
            acc[CourseType.id] = CourseType?.name;
          }
          return acc;
        }, {});
        setCourseProgramData(programCoursePlans);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getProgramCourse = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.PROGRAM_COURSE)
      .then((res) => {
        const CourseTypes = res?.data?.results?.reduce((acc, CourseType) => {
          acc[CourseType?.id] = CourseType.university_course_name;
          return acc;
        }, {});
        const CoursePreQuisites = res?.data?.results?.reduce(
          (acc, CourseType) => {
            acc[CourseType?.id] = CourseType.university_course_name;
            return acc;
          },
          {}
        );
        setProgramPrerequisite(CoursePreQuisites);
        setProgramCourse(CourseTypes);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const getProgramCoursePlanData = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.COURSE_PREREQUISITES)
      .then((res) => {
        const CourseTypes = res?.data?.results?.reduce((acc, CourseType) => {
          if (!Object.values(acc).includes(CourseType.program_name)) {
            acc[CourseType.program] = CourseType.program_name;
          }
          return acc;
        }, {});
        setCourseProgramNames(CourseTypes);
        const CoursePlans = res?.data?.results?.reduce((acc, CourseType) => {
          if (!Object.values(acc).includes(CourseType.course_plan_name)) {
            acc[CourseType.course_plan] = CourseType.course_plan_name;
          }
          return acc;
        }, {});

        setCoursePlanNames(CoursePlans);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getData = (filter) => {
    const cleanedFilter = Object.fromEntries(
      Object.entries(filter).filter(([_, value]) => value !== "all")
    );

    if (Object.keys(cleanedFilter).length > 0) {
      const queryParams = new URLSearchParams();
      if (cleanedFilter.program) queryParams.append("course_plan__program", cleanedFilter.program);
      if (cleanedFilter.plan) queryParams.append("course_plan", cleanedFilter.plan);

      const url = `${ApiConfig.COURSE_PREREQUISITES}?${queryParams.toString()}`;

      simpleGetCallWithTokenAxios(url)
        .then((res) => {
          const sortedStatuses = sortProgramCourseStatuses(res?.data?.results);
          setCourseProgramStatuses(sortedStatuses);
          setFilteredStatuses(sortedStatuses);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      simpleGetCallWithTokenAxios(ApiConfig.COURSE_PREREQUISITES)
        .then((res) => {
          const sortedStatuses = sortProgramCourseStatuses(res?.data?.results);
          setCourseProgramStatuses(sortedStatuses);
          setFilteredStatuses(sortedStatuses);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  useEffect(() => {
    getProgramCourse();
    getProgramCoursePlanData();
    getData(false);
    getProgramList();
    getProgramCoursePlan();
  }, []);
  const editProgramCourseStatus = async () => {
    const prerequisiteCourses = editProgramCourseStatusData?.prerequisite_course || [];
    if (prerequisiteCourses.length === 0) {
      ToastMsg("error", "Please select at least one prerequisite course.");
      return;
    }
    setLoading(true);
    try {
      const promises = prerequisiteCourses.map(async (prerequisiteCourse) => {
        const body = {
          course_plan: editProgramCourseStatusData?.course_plan,
          program_course: editProgramCourseStatusData?.program_course,
          prerequisite_course: prerequisiteCourse,
          is_active: editProgramCourseStatusData?.is_active,
        };

        const res = await multipartPatchCallWithErrorResponseAxios(
          `${ApiConfig.COURSE_PREREQUISITES}${editProgramCourseStatusData.id}/`,
          body
        );

        if (res.error) {
          throw new Error(res?.json?.non_field_errors[0]);
        }
      });

      await Promise.all(promises);
      ToastMsg("success", t("successUpdatedData"));
      getData(false);
      editToggle();
    } catch (err) {
      ToastMsg("error", err);
    } finally {
      setLoading(false);
    }
  };

  const addProgramCourseStatus = async () => {
    const prerequisiteCourses = addProgramCourseStatusData?.prerequisite_course || [];
    if (prerequisiteCourses.length === 0) {
      ToastMsg("error", "Please select at least one prerequisite course.");
      return;
    }
    setLoading(true);
    try {
      const promises = prerequisiteCourses.map(async (prerequisiteCourse) => {
        const body = {
          course_plan: addProgramCourseStatusData?.course_plan,
          program_course: addProgramCourseStatusData?.program_course,
          prerequisite_course: prerequisiteCourse,
          is_active: addProgramCourseStatusData?.is_active,
        };

        const res = await multipartPostCallWithErrorResponseAxios(ApiConfig.COURSE_PREREQUISITES, body);

        if (res.error) {
          throw new Error(res?.json?.non_field_errors[0]);
        }
      });

      await Promise.all(promises);
      ToastMsg("success", t("dataAddedSuccessFully"));
      getData(false);

      setAddProgramCourseStatusData({
        program_name: "",
        course_plan: "",
        program_course: "",
        prerequisite_course: [],
        is_active: false,
      });

      addToggle();
    } catch (err) {
      ToastMsg("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (editModal) {
      editProgramCourseStatus();
    } else {
      addProgramCourseStatus();
      addToggle();
    }
  };

  useEffect(() => {
    const initialStates = filteredStatuses?.reduce((acc, status) => {
      acc[status?.id] = status?.is_active;
      return acc;
    }, {});
    setSwitchStates(initialStates);
  }, [filteredStatuses]);

  const toggleSwitch = (id, is_active) => {
    successToggle();
    // const currentStatus = switchStates[id];
    const newStatus = !is_active;
    const payLoadFormData = new FormData();
    payLoadFormData.append("is_active", newStatus);
    if (newStatus === true) {
      setdataStatus("active");
    } else {
      setdataStatus("inactive");
    }

    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    multipartPatchCallWithErrorResponseAxios(
      `${ApiConfig.COURSE_PREREQUISITES}${id}/`,
      payLoadFormData
    )
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          getData(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        ToastMsg("error", err);
        setLoading(false);
      });
  };

  const handleFilter = (event) => {
    event.preventDefault();
    if (filterData.program || filterData.plan) {
      getData(filterData);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: t("confirmDeleteEntry"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#faab7a",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
          confirmButtonText: t("yesDeleteIt"),
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCallWithErrorResponseAxios(
          `${ApiConfig.COURSE_PREREQUISITES}${id}/`
        )
          .then((res) => {
            if (!res.error) {
              ToastMsg("success", t("successDeleteData"));
              getData(false);
            } else {
              ToastMsg(
                "error",
                t("thisRecordCantBeDeletedMakeFieldActive")
              );
            }
          })
          .catch((err) => {
            // ToastMsg('error', t("thisRecordCantBeDeletedMakeFieldActive"));
          });
      }
    });
  };
  const memoizedData = React.useMemo(() => filteredStatuses, [filteredStatuses]);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'serial',
        header: t("no"),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'program_name',
        header: t("program"),
      },
      {
        accessorKey: 'course_plan_name',
        header: t("plan"),
      },
      {
        accessorKey: 'total_credit',
        header: t("course"),
        cell: ({ row }) => {
          return (
            <>
              {row.original?.program_course_name}
              {row.original?.total_credit && ` - ${row.original.total_credit}`}
            </>
          );
        },
      },
      {
        accessorKey: 'prerequisite_course_name',
        header: t("prerequisiteCourse"),
      },
      {
        accessorKey: 'is_active',
        header: t("isActive"),
        cell: ({ row }) => {
          const { id, is_active } = row.original;
          return (
            <div className="text-nowrap custom-action action-column" style={{ alignItems: 'center', gap: '10px' }}>
              <FormGroup switch>
                <Input
                  className="status-column"
                  type="switch"
                  checked={is_active}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleSwitch(id, is_active);
                  }}
                  style={{
                    height: 20,
                    width: 40,
                  }}
                />
              </FormGroup>
            </div>
          );
        },
      },
      {
        accessorKey: 'actions',
        header: t("actions"),
        meta: { isToggleSwitch: true },
        cell: ({ row }) => {
          const { id, is_active } = row.original;

          return (
            <div className="text-nowrap col-1">
              <a
                data-toggle="modal"
                data-target="#responsive-modal-edit"
                data-original-title="Edit"
              >
                <i className="fa fa-pencil text-inverse m-r-10 fa-2x hoverPointer">
                  <FaPencilAlt
                    style={{ color: '#faab7a', width: 18, height: 18 }}
                    onClick={() => editToggle(row.original)}
                  />
                </i>
              </a>
              <a data-toggle="tooltip" data-original-title="Delete">
                <i className="fa fa-close text-danger fa-2x hoverPointer">
                  <ImCross
                    style={{ color: '#e36d6d', width: 15, height: 15 }}
                    onClick={() => { handleDelete(id) }}
                  />
                </i>
              </a>
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <div className="page-wrapper">
        <div className="card">
          <div className="card-header bg-info">
            <h4 className="m-b-0 text-white">{t("managePrerequisiteCourses")}</h4>
          </div>
          <div className="card-body">
            <div className="card col-12  center  border p-5">
              <h4 className="card-title">
                <MdFilterList className=" mdi mdi-filter-variant" /> {t("filter")}
                {t("results")}
              </h4>
              <div id="education_fields"></div>
              <div className="row">
                <div className="col-sm-12">
                  <Form className="form-horizontal " onSubmit={handleFilter}>
                    <div className="form-group validate">
                      <Label className="control-label">{t("program")}:</Label>
                      <select
                        className="form-select form-control select2"
                        id="program"
                        name="program"
                        aria-invalid="false"
                        value={filterData.program}
                        onChange={(e) =>
                          setFilterData((prevState) => ({
                            ...prevState,
                            program: e.target.value,
                          }))
                        }
                      >
                        <option value="" disabled selected>
                          ---Select---
                        </option>
                        <option value="all">{t("all")}</option>
                        {Object.entries(courseProgramNames).map(
                          ([id, name]) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <Label className="control-label">Plan:</Label>
                      <select
                        className="form-select form-control select2"
                        id="plan"
                        name="plan"
                        aria-invalid="false"
                        value={filterData.plan}
                        onChange={(e) =>
                          setFilterData((prevState) => ({
                            ...prevState,
                            plan: e.target.value,
                          }))
                        }
                      >
                        <option value="" disabled selected>
                          ---Select---
                        </option>
                        <option value="all">{t("all")}</option>
                        {Object.entries(coursePlanNames).map(([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-info waves-effect waves-light"
                    >
                      <FaFilter className="fa fa-filter" /> {t("filter")}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="text-end">
              <Button
                type="button"
                className="btn btn-info mb-3"
                onClick={addToggle}
              >
                <i className="bi bi-plus-circle-fill me-2 mt-1"></i>{t("createNew")}
              </Button>
            </div>
            <CommonDataTable columns={columns} data={memoizedData} />
          </div>
        </div>
      </div>
      <Modal
        isOpen={editModal}
        addModuleForm={editToggle}
        {...args}
        className="modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Edit Prerequisite Course</h4>
            <Button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={editToggle}
            >
              ×
            </Button>
          </div>
          <div className="modal-body">
            <Form className="m-t-40" onSubmit={handleSubmit}>
              <div className="form-body">
                <div className="form-group validate">
                  <Label className="control-label">{t("program")}:</Label>
                  <select
                    className="form-select form-control select2"
                    id="program_name"
                    name="program_name"
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData?.program_name}
                    onChange={(e) =>
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        program_name: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(courseProgramName).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Plan:</Label>
                  <select
                    className="form-select form-control select2"
                    id="course_plan"
                    name="course_plan"
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData.course_plan}
                    onChange={(e) =>
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        course_plan: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(courseProgramData).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Course:</Label>
                  <select
                    className="form-select form-control select2"
                    id="program_course"
                    name="program_course"
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData.program_course}
                    onChange={(e) =>
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        program_course: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    <optgroup label="Core Courses">
                      {Object.entries(ProgramCourse).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </optgroup>

                    {/* <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(ProgramCourse).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">{t("prerequisiteCourses")}:</Label>
                  <select
                    className="form-select form-control select2"
                    id="prerequisite_course"
                    name="prerequisite_course"
                    multiple
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData.prerequisite_course}
                    onChange={(e) => {
                      const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        prerequisite_course: selectedValues,
                      })
                    }
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <optgroup label="Core Courses">
                      {Object.entries(ProgramCourse).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </optgroup>
                    {/* {Object.entries(ProgramCourse).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="form-group">
                  <div className="controls">
                    <FormGroup check>
                      <Label check className="custom-control-label">
                        <Input
                          type="checkbox"
                          name="is_active"
                          checked={editProgramCourseStatusData.is_active}
                          onChange={(e) =>
                            setEditProgramCourseStatusData({
                              ...editProgramCourseStatusData,
                              is_active: e.target.checked,
                            })
                          }
                        />
                        {t("isActive")}
                      </Label>
                    </FormGroup>
                    <div className="help-block"></div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="button"
                  className="btn btn-default waves-effect"
                  data-dismiss="modal"
                  onClick={editToggle}
                >
                  {t("close")}
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary stylehundredone"
                >
                  {t("saveChanges")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={addModal}
        addModuleForm={addToggle}
        {...args}
        className="modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{t("addPrerequisiteCourse")}</h4>
            <Button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={addToggle}
            >
              ×
            </Button>
          </div>
          <div className="modal-body">
            <Form className="m-t-40" onSubmit={handleSubmit}>
              <div className="form-body">
                <div className="form-group validate">
                  <Label className="control-label">{t("program")}:</Label>
                  <select
                    className="form-select form-control select2"
                    id="program_name"
                    name="program_name"
                    required
                    aria-invalid="false"
                    value={addProgramCourseStatusData?.program_name}
                    onChange={(e) =>
                      setAddProgramCourseStatusData({
                        ...addProgramCourseStatusData,
                        program_name: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(courseProgramName).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Plan:</Label>
                  <select
                    className="form-select form-control select2"
                    id="course_plan"
                    name="course_plan"
                    required
                    aria-invalid="false"
                    value={addProgramCourseStatusData.course_plan}
                    onChange={(e) =>
                      setAddProgramCourseStatusData({
                        ...addProgramCourseStatusData,
                        course_plan: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(courseProgramData).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Course:</Label>
                  <select
                    className="form-select form-control select2"
                    id="program_course"
                    name="program_course"
                    required
                    aria-invalid="false"
                    value={addProgramCourseStatusData.program_course}
                    onChange={(e) =>
                      setAddProgramCourseStatusData({
                        ...addProgramCourseStatusData,
                        program_course: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    <optgroup label="Core Courses">
                      {Object.entries(ProgramCourse).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">{t("prerequisiteCourses")}:</Label>
                  <select
                    className="select2 m-b-10 select2-multiple"
                    multiple
                    data-placeholder="Choose"
                    required
                    style={{ width: "100%" }}
                    id="prerequisite_course"
                    name="prerequisite_course"
                    value={addProgramCourseStatusData.prerequisite_course}
                    onChange={(e) => {
                      const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
                      setAddProgramCourseStatusData({
                        ...addProgramCourseStatusData,
                        prerequisite_course: selectedValues,
                      })
                    }
                    }
                  >
                    <option value="" disabled>Select</option>
                    <optgroup label="Core Courses">
                      {Object.entries(ProgramPrerequisite).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
                <div className="form-group">
                  <div className="controls">
                    <FormGroup check>
                      <Label check className="custom-control-label">
                        <Input
                          type="checkbox"
                          name="is_active"
                          checked={addProgramCourseStatusData.is_active}
                          onChange={(e) =>
                            setAddProgramCourseStatusData({
                              ...addProgramCourseStatusData,
                              is_active: e.target.checked,
                            })
                          }
                        />
                        {t("isActive")}
                      </Label>
                    </FormGroup>
                    <div className="help-block"></div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="button"
                  className="btn btn-default waves-effect"
                  data-dismiss="modal"
                  onClick={addToggle}
                >
                  {t("close")}
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary stylehundredone"
                >
                  {t("saveChanges")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal isOpen={success} addModuleForm={successToggle} {...args}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <div
            style={{
              padding: 20,
              // backgroundColor: 'red',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                borderRadius: 100,
                width: 102,
                height: 102,
                backgroundColor: "green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiCheck
                style={{
                  width: 100,
                  height: 100,
                  backgroundColor: "white",
                  borderRadius: 100,
                  color: "green",
                }}
              />
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: 10,
              }}
            >
              <h2>{t("success")}</h2>
              <p>{t("statusChanged")}</p>
              <Button
                className="confirm"
                color="info"
                size="lg"
                onClick={successToggle}
              >
                {t("ok")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ManagePreReq;