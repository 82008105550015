import React, { useEffect, useState, lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import ProtectedRoute from './ProtectedRoute';
import ProtectedRouteForDynamicPages from './ProtectedRouteForDynamicPages';
import ManagePreReq from '../pages/AcademicManagementData/ManageAcadamics/ManagePreReq';
import OfferedCoursesBySession from '../pages/AcademicManagementData/ManageAcadamics/OfferedCoursesBySession';
/****Layouts*****/

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Pages ****/
const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const Analytical = Loadable(lazy(() => import('../views/dashboards/Analytical')));
const Demographical = Loadable(lazy(() => import('../views/dashboards/Demographical')));
const Modern = Loadable(lazy(() => import('../views/dashboards/Modern')));

/***** Apps ****/
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Chat = Loadable(lazy(() => import('../views/apps/chat/Chat')));
const Contacts = Loadable(lazy(() => import('../views/apps/contacts/Contacts')));
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/CalendarApp')));
const Email = Loadable(lazy(() => import('../views/apps/email/Email')));
const Shop = Loadable(lazy(() => import('../views/apps/ecommerce/Shop')));
const ShopDetail = Loadable(lazy(() => import('../views/apps/ecommerce/ShopDetail')));
const Treeview = Loadable(lazy(() => import('../views/apps/treeview/TreeView')));
const TicketList = Loadable(lazy(() => import('../views/apps/ticket/TicketList')));
const TicketDetail = Loadable(lazy(() => import('../views/apps/ticket/TicketDetail')));

/***** Ui Elements ****/
const Alerts = Loadable(lazy(() => import('../views/ui/Alerts')));
const Badges = Loadable(lazy(() => import('../views/ui/Badges')));
const Buttons = Loadable(lazy(() => import('../views/ui/Buttons')));
const Cards = Loadable(lazy(() => import('../views/ui/Cards')));
const Grid = Loadable(lazy(() => import('../views/ui/Grid')));
const Tables = Loadable(lazy(() => import('../views/ui/Tables')));
const Forms = Loadable(lazy(() => import('../views/ui/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../views/ui/Breadcrumbs')));
const Dropdowns = Loadable(lazy(() => import('../views/ui/DropDown')));
const BtnGroup = Loadable(lazy(() => import('../views/ui/BtnGroup')));
const Collapse = Loadable(lazy(() => import('../views/ui/Collapse')));
const ListGroup = Loadable(lazy(() => import('../views/ui/ListGroup')));
const Modal = Loadable(lazy(() => import('../views/ui/Modal')));
const Navbar = Loadable(lazy(() => import('../views/ui/Navbar')));
const Nav = Loadable(lazy(() => import('../views/ui/Nav')));
const Pagination = Loadable(lazy(() => import('../views/ui/Pagination')));
const Popover = Loadable(lazy(() => import('../views/ui/Popover')));
const Progress = Loadable(lazy(() => import('../views/ui/Progress')));
const Spinner = Loadable(lazy(() => import('../views/ui/Spinner')));
const Tabs = Loadable(lazy(() => import('../views/ui/Tabs')));
const Toasts = Loadable(lazy(() => import('../views/ui/Toasts')));
const Tooltip = Loadable(lazy(() => import('../views/ui/Tooltip')));

/***** Form Layout Pages ****/
const FormBasic = Loadable(lazy(() => import('../views/form-layouts/FormBasic')));
const FormGrid = Loadable(lazy(() => import('../views/form-layouts/FormGrid')));
const FormGroup = Loadable(lazy(() => import('../views/form-layouts/FormGroup')));
const FormInput = Loadable(lazy(() => import('../views/form-layouts/FormInput')));

/***** Form Pickers Pages ****/
const Datepicker = Loadable(lazy(() => import('../views/form-pickers/DateTimePicker')));
const TagSelect = Loadable(lazy(() => import('../views/form-pickers/TagSelect')));

/***** Form Validation Pages ****/
const FormValidate = Loadable(lazy(() => import('../views/form-validation/FormValidation')));
const FormSteps = Loadable(lazy(() => import('../views/form-steps/Steps')));

const FormEditor = Loadable(lazy(() => import('../views/form-editor/FormEditor')));
/***** Table Pages ****/
const Basictable = Loadable(lazy(() => import('../views/tables/TableBasic')));
const CustomReactTable = Loadable(lazy(() => import('../views/tables/CustomReactTable')));
const ReactBootstrapTable = Loadable(lazy(() => import('../views/tables/ReactBootstrapTable')));

/***** Chart Pages ****/
const ApexCharts = Loadable(lazy(() => import('../views/charts/ApexCharts')));
const ChartJs = Loadable(lazy(() => import('../views/charts/ChartJs')));

/***** Sample Pages ****/
const StarterKit = Loadable(lazy(() => import('../views/sample-pages/StarterKit')));
const Profile = Loadable(lazy(() => import('../views/sample-pages/Profile')));
const Gallery = Loadable(lazy(() => import('../views/sample-pages/Gallery')));
const SearchResult = Loadable(lazy(() => import('../views/sample-pages/SearchResult')));
const HelperClass = Loadable(lazy(() => import('../views/sample-pages/HelperClass')));

/***** Icon Pages ****/
const Bootstrap = Loadable(lazy(() => import('../views/icons/Bootstrap')));
const Feather = Loadable(lazy(() => import('../views/icons/Feather')));

/***** Map Pages ****/
const CustomVectorMap = Loadable(lazy(() => import('../views/maps/CustomVectorMap')));

/***** Widget Pages ****/
const Widget = Loadable(lazy(() => import('../views/widget/Widget')));

/***** CASL Access Control ****/
const CASL = Loadable(lazy(() => import('../views/apps/accessControlCASL/AccessControl')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

// -------------------------------------------------------------------------------------------------------
// <========================================> UNIVERSITY ROUTES START <=========================================>
// -------------------------------------------------------------------------------------------------------

// *********application form steps************
const FormStepsMain = Loadable(lazy(() => import('../pages/applicationSteps/StepsMain')));
// *********apllicatnt profile details************
const ApplicantProfile = Loadable(lazy(() => import('../pages/applicantProfile/ApplicantProfile')));

const ApplicantProgramApplied = Loadable(
  lazy(() => import('../pages/applicantProgram/ApplicantProgramApplied')),
);
const ApplicantProgramListing = Loadable(
  lazy(() => import('../pages/applicantProgram/ApplicantProgramListing')),
);

const AddmissionIntek = Loadable(lazy(() => import('../pages/applicantProgram/AddmissionIntek')));
const Agentapplicationself = Loadable(lazy(() => import('../pages/agent/Agentapplicationself')));
const AgentappliRequest = Loadable(lazy(() => import('../pages/agent/AgentappliRequest')));
const Agentearning = Loadable(lazy(() => import('../pages/agent/Agentearning')));
const Agentfavorites = Loadable(lazy(() => import('../pages/agent/Agentfavorites')));
const AgentRating = Loadable(lazy(() => import('../pages/agent/AgentRating')));
const AgentunivAppli = Loadable(lazy(() => import('../pages/agent/AgentunivAppli')));
const Complaintagent = Loadable(lazy(() => import('../pages/agent/Complaintagent')));
const Tranferself = Loadable(lazy(() => import('../pages/agent/Tranferself')));
const Transactionagentlist = Loadable(lazy(() => import('../pages/agent/Transactionagentlist')));
const UserNotifications = Loadable(lazy(() => import('../pages/agent/UserNotifications')));

const AgentSignup = Loadable(lazy(() => import('../pages/agent/agentSignup/AgentSignup')));

const AgentProfile = Loadable(lazy(() => import('../pages/agent/agentProfile/AgentProfile')));

const ChangePassword = Loadable(lazy(() => import('../pages/agent/ChangePassword')));

// *********** auth pages *****************
const Login = Loadable(lazy(() => import('../pages/authPages/Login')));
const Signup = Loadable(lazy(() => import('../pages/authPages/Signup')));

// *********** admission auth pages *****************
const AdmissionLogin = Loadable(lazy(() => import('../pages/authAdmission/Login')));
const AdmissionSignup = Loadable(lazy(() => import('../pages/authAdmission/Signup')));
// -------------------------------------------------------------------------------------------------------
// <========================================> UNIVERSITY ROUTES END <=========================================>
// -------------------------------------------------------------------------------------------------------

/*****Routes******/

//////////////////////////////SMR
const TasksCommunicationsSupervisor = Loadable(
  lazy(() => import('../pages/GraduateModule/TasksCommunicationsSupervisor')),
);
const TaskCommStu = Loadable(lazy(() => import('../pages/GraduateModule/TaskCommStu')));
const Courseplan = Loadable(lazy(() => import('../pages/Course/Courseplan')));
const AddUni = Loadable(lazy(() => import('../pages/UniversityAdd/AddUni')));
const AddPrgm = Loadable(lazy(() => import('../pages/UniversityAdd/AddPrgm')));
const FormEditorOne = Loadable(lazy(() => import('../pages/Course/FormEditorOne')));
const TasksCommunicationsStudents = Loadable(
  lazy(() => import('../pages/GraduateModule/TasksCommunicationsStudents')),
);

///////////////RESHMA
const GradDefenceEvaluator = Loadable(
  lazy(() => import('../pages/GraduateModule/GradDefenceEvaluator')),
);
const AddPublications = Loadable(lazy(() => import('../pages/GraduateModule/AddPublications')));
const UniversityDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/UniversityDetails')),
);
const UniversitiesFile = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/UniversitiesFile')),
);
const ManageCampusProgram = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageCampusProgram')),
);
const ManageCampus = Loadable(lazy(() => import('../pages/manageUniversityFolder/ManageCampus')));
const ManageCampusFaculity = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageCampusFaculity')),
);
const ManageFaculity = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageFaculity')),
);
const ManageProgram = Loadable(lazy(() => import('../pages/manageUniversityFolder/ManageProgram')));
const ProgramDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ProgramDetails')),
);
const CampusDetails = Loadable(lazy(() => import('../pages/manageUniversityFolder/CampusDetails')));
const ManageUniversities = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageUniversities')),
);
//////////////////SHOBI
const AddResearcher = Loadable(lazy(() => import('../pages/GraduateModule/AddResearcher')));
const EditResearcher = Loadable(lazy(() => import('../pages/GraduateModule/EditResearcher')));
const Courses = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/Courses')),
);
const ManageCourseTypes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageCourseTypes')),
);
const EditCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/EditCourse')),
);
const EditProgramCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/EditProgramCourse')),
);
const AddOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/AddOfferedCourse')),
);
const EditOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/EditOfferedCourse')),
);
const GradDefenses = Loadable(lazy(() => import('../pages/GraduateModule/GradDefenses')));
const EditDefense = Loadable(lazy(() => import('../pages/GraduateModule/EditDefense')));
const AddDefense = Loadable(lazy(() => import('../pages/GraduateModule/AddDefense')));
const ProgramLevels = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ProgramLevels')),
);
const ProgramModes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ProgramModes')),
);
const Qualification = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/Qualification')),
);
const QualificationLevels = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/QualificationLevels')),
);
const StudentType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StudentType')),
);
const StudyType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StudyType')),
);
const UniActivityType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/UniActivityType')),
);
const StdCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StdCourse')),
);
const CourseReg = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/CourseReg')),
);
const ApplicationStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/ApplicationStatus')),
);
const VerificationStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/VerificationStatus')),
);
const Attachment = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Attachment')),
);
const ContactTypes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/ContactTypes')),
);
const Currencies = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Currencies')),
);
const LanguageProfiency = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/LanguageProfiency')),
);
const CoreLanguage = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/CoreLanguage')),
);
const MaritalStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/MaritalStatus')),
);
const PaymentModes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/PaymentModes')),
);
const Relegions = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Relegions')),
);
const Countries = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Countries')),
);
const States = Loadable(lazy(() => import('../pages/AcademicManagementData/CoreData/States')));
const Cities = Loadable(lazy(() => import('../pages/AcademicManagementData/CoreData/Cities')));
const StdCourseReg = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StdCourseReg')),
);
const ManageOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageOfferedCourse')),
);
const CourseByPlan = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/CourseByPlan')),
);
const ManageCoursePlan = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageCoursePlan')),
);

const ProgramCoursePlan = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ProgramCourses')),
);

const ManageAcadamicSession = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageAcadamicSession')),
);
/////////////////NANDHU
const Agentprofilev2 = Loadable(lazy(() => import('../pages/agent/Agentprofilev2')));
const Admincampus = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Admincampus')));
const Publications = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Publication')));

const Admincampusfaculty = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/Admincampusfaculty')),
);
const Adminfaculty = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Adminfaculty')));
const Adminuniversity = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Adminuniversity')));
const Facultydetailss = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Facultydetailss')));

const CampusFacultyDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/CampusFacultyDetails')),
);

const Addcampusprgm = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Addcampusprgm')));
const AddDefenseEvaluator = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/AddDefenseEvaluator')),
);
const EditDefenseEvaluator = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/EditDefenseEvaluator')),
);
const AddCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/AddCourse')),
);

const AddProgramCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/AddProgramCourse')),
);

const Publication = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Publication')));
const TasksStudents = Loadable(lazy(() => import('../pages/AdduniversityAdmin/TasksStudents')));
const Tasksupervisor = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Tasksupervisor')));
const AddTasksupervisor = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/AddTasksupervisor')),
);
const EditTaskSupervisor = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/EditTaskSupervisor')),
);
//////////////////ASHIQ
const EditResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/EditResearchActivities')),
);
const ManageGradApplicationStatuses = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradApplicationStatuses')),
);
const ManageApplicationTypeChecklists = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageApplicationTypeChecklists')),
);
const GradeApplication1 = Loadable(lazy(() => import('../pages/GraduateModule/GradeApplication1')));
const ManageGradAuthorRole = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradAuthorRole')),
);
const ManageResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageResearchActivities')),
);
const ApplicationInterface2 = Loadable(
  lazy(() => import('../pages/GraduateModule/ApplicationInferface2')),
);
const AddResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/AddResearchActivities')),
);
const ManageModules = Loadable(lazy(() => import('../pages/menuManagement/ManageModules')));
const ManageModulePages = Loadable(lazy(() => import('../pages/menuManagement/ManageModulePages')));
const ManageGroupMenus = Loadable(lazy(() => import('../pages/menuManagement/ManageGroupMenus')));
const ManageGroupMenuLinks = Loadable(
  lazy(() => import('../pages/menuManagement/ManageGroupMenusLinks')),
);
const ManageUserInActiveMenu = Loadable(
  lazy(() => import('../pages/menuManagement/ManageUserInActiveMenu')),
);
const ManageUserInActiveLinks = Loadable(
  lazy(() => import('../pages/menuManagement/ManageUserInActiveLinks')),
);
const AddModulePage = Loadable(lazy(() => import('../pages/menuManagement/AddModulePage')));
const EditModulePage = Loadable(lazy(() => import('../pages/menuManagement/EditModulePage')));
const AddGroupMenuLinks = Loadable(lazy(() => import('../pages/menuManagement/AddGroupMenuLinks')));
const EditGroupMenuLinks = Loadable(
  lazy(() => import('../pages/menuManagement/EditGroupMenuLinks')),
);
const CourseMarksScheme = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CourseMarksScheme')),
);
const ManageOfferedCourses = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageOfferedCourses')),
);
const SummaryOfCurrentRegistrations = Loadable(
  lazy(() => import('../pages/AcademicManagementData/SummaryOfCurrentRegistrations')),
);
const EditPublication = Loadable(lazy(() => import('../pages/GraduateModule/EditPublication')));
const ManageGradApplicationTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradApplicationTypes')),
);
const ManageGradDefenseStatuses = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradDefenseStatuses')),
);
const ManageGradDefenseTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradDefenseTypes')),
);
const ManageDefenseVenues = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageDefenseVenues')),
);
const ManageGradEvaluationRatings = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradEvaluationRatings')),
);
const ManageGraduateIntakeStructures = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGraduateIntakeStructures')),
);
const ManageStructureMilestoneChecklists = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageStructureMilestoneChecklists')),
);
const GradPublicationTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/GradPublicationTypes')),
);
const GradResearchAreas = Loadable(lazy(() => import('../pages/GraduateModule/GradResearchAreas')));
const GradResearchLanguages = Loadable(
  lazy(() => import('../pages/GraduateModule/GradResearchLanguages')),
);
const GradResearchStatus = Loadable(
  lazy(() => import('../pages/GraduateModule/GradResearchStatus')),
);
const ManageAgenttypess = Loadable(lazy(() => import('../pages/GraduateModule/ManageAgenttypess')));
const ResearchesStructure = Loadable(
  lazy(() => import('../pages/GraduateModule/ResearchesStructure')),
);
const ManageAgentLevelrates = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgentLevelrates')),
);
const StructureMilestone = Loadable(
  lazy(() => import('../pages/GraduateModule/StructureMilestone')),
);
const SupervisorTypes = Loadable(lazy(() => import('../pages/GraduateModule/SupervisorTypes')));
const GradTemplates = Loadable(lazy(() => import('../pages/GraduateModule/GradTemplates')));
const GradResearchers = Loadable(lazy(() => import('../pages/GraduateModule/GradResearchers')));
const ManageResearches = Loadable(lazy(() => import('../pages/GraduateModule/ManageResearches')));
const AddResearch = Loadable(lazy(() => import('../pages/GraduateModule/AddResearch')));
const EditResearch = Loadable(lazy(() => import('../pages/GraduateModule/EditResearch')));

//////////////////////THUSHARA
const UniversityManagementzzz = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/UniversityManagementzzz')),
);
const ProgramDataDetails = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/ProgramDataDetails')),
);

const AdimissionIntake = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/AddmissionIntek')),
);

const ApplicantProgramApp = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/ApplicantProgramApp')),
);
const ManageGradActivityTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradActivityTypes')),
);
const Addgeneral = Loadable(lazy(() => import('../pages/ApplicantInfo/Addgeneral')));
const Attachmentadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Attachmentadd')));
const Contactinformation = Loadable(
  lazy(() => import('../pages/ApplicantInfo/Contactinformation')),
);
const Experienceadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Experienceadd')));
const Honorsadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Honorsadd')));
const Languageadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Languageadd')));
const MedicalInformation = Loadable(
  lazy(() => import('../pages/ApplicantInfo/MedicalInformation')),
);
const Qualificationsadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Qualificationsadd')));
const Referencesadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Referencesadd')));
const VisaInformationn = Loadable(lazy(() => import('../pages/ApplicantInfo/VisaInformationn')));

////New UserUniversity...////...///
const QAbanned = Loadable(lazy(() => import('../pages/Useruniversity/QAbanned')));
const Universityrestriction = Loadable(
  lazy(() => import('../pages/Useruniversity/Universityrestriction')),
);
const ManageApplicantsearch = Loadable(
  lazy(() => import('../pages/Useruniversity/ManageApplicantsearch')),
);
const ManageBanAgent = Loadable(lazy(() => import('../pages/Useruniversity/ManageBanAgent')));
const ListUsers = Loadable(lazy(() => import('../pages/GraduateModule/Listusers')));
const ManageAgentApplication = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgentApplication')),
);
const ManageTransactionTypessss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageTransactionTypessss')),
);
const UniversityRestrictionsss = Loadable(
  lazy(() => import('../pages/Useruniversity/UniversityRestrictionsss')),
);
const ManageTransferss = Loadable(lazy(() => import('../pages/GraduateModule/ManageTransferss')));
const AddApplicationss = Loadable(lazy(() => import('../pages/GraduateModule/AddApplicationss')));
const ManageUserRatingsss = Loadable(
  lazy(() => import('../pages/Useruniversity/ManageUserRatingsss')),
);
const Manageuserss = Loadable(lazy(() => import('../pages/GraduateModule/Manageuserss')));
const AddUser = Loadable(lazy(() => import('../pages/GraduateModule/AddUser')));
const ManageAgenttwo = Loadable(lazy(() => import('../pages/GraduateModule/ManageAgenttwo')));
const ManageAgentLevels = Loadable(lazy(() => import('../pages/GraduateModule/ManageAgentLevels')));
const ManageComplaints = Loadable(lazy(() => import('../pages/GraduateModule/ManageComplaints')));

// NEW ROUTES HERE..........
const University = Loadable(lazy(() => import('../pages/universityManagement/University')));
const AddUniversity = Loadable(lazy(() => import('../pages/universityManagement/AddUniversity')));

const AddAdmissionIntake = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/AddAdmissionIntake')))
const ProcessingSteps = Loadable(lazy(() => import('../pages/AdmissionAdmin/ProcessingSteps')))
const FeeTypes = Loadable(lazy(() => import('../pages/AdmissionAdmin/FeeTypes')))
const ProgramProcessingSteps = Loadable(lazy(() => import('../pages/AdmissionAdmin/ProgramProcessingSteps')))
const UserRoleProcessingSteps = Loadable(lazy(() => import('../pages/AdmissionAdmin/UserRoleProcessingSteps')),
);
const AdmissionActivityTypes = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/AdmissionActivityTypes')),
);
const IntakeActivities = Loadable(lazy(() => import('../pages/AdmissionAdmin/IntakeActivities')));
const IntakePrograms = Loadable(lazy(() => import('../pages/AdmissionAdmin/IntakePrograms')));
const ProgramQualifications = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/ProgramQualifications')),
);
const ProgramEligibility = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/ProgramEligibility')),
);
const ProgramAttachments = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/ProgramAttachments')),
);
const ProcessingStatus = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/ProcessingStatus')),
);
const OAFeePaymentModes = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/FeePaymentModes')),
);
const FeeStatus = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/FeeStatus')),
);
const ApplicationStatuses = Loadable(
  lazy(() => import('../pages/AdmissionAdmin/ApplicationStatuses')),
);

const userRole = JSON.parse(localStorage.getItem('userRole'));
const isUniversityAdmin = userRole?.role?.name === 'admin';
const userRoleAgent = JSON.parse(localStorage.getItem('userRole'))?.role?.name;

// let userAccessPermissionsList = [];
// async function fetchData() {

//   try {
//       const res = await simpleGetCallWithTokenAxios(ApiConfig.GET_USER_MENU);
//       userAccessPermissionsList = res.data;
//   } catch (err) {
//       console.error("Error fetching user permissions:", err);
//       userAccessPermissionsList = [];
//   }

//   console.log(" This is test ", userAccessPermissionsList);
// }

// // fetchData();
// let counter = 1
// async function hasPermission(itemTitle) {
//   await fetchData();
//   console.log("counter", counter);
//   counter += 1
//   return userAccessPermissionsList.some((userPermission) => {
//     const permissionName = userPermission?.display_text?.toLowerCase();
//     console.log(permissionName === itemTitle?.toLowerCase(), "==============1111=============");
//     return permissionName === itemTitle?.toLowerCase();
//   });
// };

// async function  ProtectedRoute(element, permissionName) {
//   // const location = useLocation();
//   const perm = await hasPermission(permissionName)
//   if (!perm) {
//     console.log("1111111111111111111111111111111111");
//     return <Navigate to="/auth/login" />;
//   }
//   return element;
// };

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      // { path: '/', name: 'Home', element: <Navigate to="" /> },

      {
        path: '/',
        name: 'Home',
        element: localStorage.getItem('logedIn') ? (
          <Navigate to="/dashboards/minimal" />
        ) : (
          <Navigate to="/auth/login" />
        ),
      },
      {
        path: '/applicantProfile',
        name: 'ApplicantProfile',
        element: localStorage.getItem('logedIn') ? (
          <ApplicantProfile />
        ) : (
          <Navigate to="/admission/login" />
        ),
      },
      { path: '/dashboards/minimal', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/dashboards/analytical', name: 'Analytical', exact: true, element: <Analytical /> },
      {
        path: '/dashboards/demographical',
        name: 'Demographical',
        exact: true,
        element: <Demographical />,
      },
      { path: '/dashboards/modern', name: 'Modern', exact: true, element: <Modern /> },
      { path: '/apps/notes', name: 'notes', exact: true, element: <Notes /> },
      { path: '/apps/chat', name: 'chat', exact: true, element: <Chat /> },
      { path: '/apps/contacts', name: 'contacts', exact: true, element: <Contacts /> },
      { path: '/apps/calendar', name: 'calendar', exact: true, element: <Calendar /> },
      { path: '/apps/email', name: 'email', exact: true, element: <Email /> },
      { path: '/ecom/shop', name: 'email', exact: true, element: <Shop /> },
      { path: '/ecom/shopdetail', name: 'email', exact: true, element: <ShopDetail /> },
      { path: '/tickt/ticket-list', name: 'ticket list', exact: true, element: <TicketList /> },
      {
        path: '/tickt/ticket-detail',
        name: 'ticket detail',
        exact: true,
        element: <TicketDetail />,
      },
      { path: '/apps/treeview', name: 'email', exact: true, element: <Treeview /> },
      { path: '/ui/alerts', name: 'alerts', exact: true, element: <Alerts /> },
      { path: '/ui/badges', name: 'badges', exact: true, element: <Badges /> },
      { path: '/ui/buttons', name: 'buttons', exact: true, element: <Buttons /> },
      { path: '/ui/cards', name: 'cards', exact: true, element: <Cards /> },
      { path: '/ui/grid', name: 'grid', exact: true, element: <Grid /> },
      { path: '/ui/table', name: 'table', exact: true, element: <Tables /> },
      { path: '/ui/forms', name: 'forms', exact: true, element: <Forms /> },
      { path: '/ui/breadcrumbs', name: 'breadcrumbs', exact: true, element: <Breadcrumbs /> },
      { path: '/ui/dropdown', name: 'dropdown', exact: true, element: <Dropdowns /> },
      { path: '/ui/button-group', name: 'button group', exact: true, element: <BtnGroup /> },
      { path: '/ui/collapse', name: 'collapse', exact: true, element: <Collapse /> },
      { path: '/ui/list-group', name: 'list-group', exact: true, element: <ListGroup /> },
      { path: '/ui/modal', name: 'modal', exact: true, element: <Modal /> },
      { path: '/ui/navbar', name: 'navbar', exact: true, element: <Navbar /> },
      { path: '/ui/nav', name: 'nav', exact: true, element: <Nav /> },
      { path: '/ui/pagination', name: 'pagination', exact: true, element: <Pagination /> },
      { path: '/ui/popover', name: 'popover', exact: true, element: <Popover /> },
      { path: '/ui/progress', name: 'progress', exact: true, element: <Progress /> },
      { path: '/ui/spinner', name: 'spinner', exact: true, element: <Spinner /> },
      { path: '/ui/tabs', name: 'tabs', exact: true, element: <Tabs /> },
      { path: '/ui/toasts', name: 'toasts', exact: true, element: <Toasts /> },
      { path: '/ui/tooltip', name: 'tooltip', exact: true, element: <Tooltip /> },
      { path: '/form-layout/form-basic', name: 'form-basic', exact: true, element: <FormBasic /> },
      { path: '/form-layout/form-grid', name: 'form-grid', exact: true, element: <FormGrid /> },
      { path: '/form-layout/form-group', name: 'form-group', exact: true, element: <FormGroup /> },
      { path: '/form-layout/form-input', name: 'form-input', exact: true, element: <FormInput /> },
      {
        path: '/form-pickers/datepicker',
        name: 'datepicker',
        exact: true,
        element: <Datepicker />,
      },
      { path: '/form-pickers/tag-select', name: 'tag-select', exact: true, element: <TagSelect /> },
      { path: '/form-validation', name: 'form-validation', exact: true, element: <FormValidate /> },

      // ------------------->> UNIVERSITY PAGES ROUTE START <<----------------------------------
      { path: '/form-steps', name: 'form-steps', exact: true, element: <FormSteps /> },
      {
        path: '/applicantProfile',
        name: 'applicantProfile',
        exact: true,
        element: <ApplicantProfile />,
        // element: isUniversityAdmin ? (
        //   <ApplicantProfile />
        // ) : (
        //   <ProtectedRoute element={<ApplicantProfile />} permissionName="profile" />
        // ),
      },

      {
        path: '/form-application-steps',
        name: 'form-application-steps',
        exact: true,
        // element: <FormStepsMain />,
        element: isUniversityAdmin ? (
          <FormStepsMain />
        ) : (
          // <ProtectedRoute element={<FormStepsMain />} permissionName="profile" />
          <ProtectedRouteForDynamicPages
            element={<FormStepsMain />}
            permissionName="/form-application-steps"
          />
        ),
      },

      {
        path: '/applicantProgramApplied',
        name: 'applicantProgramApplied',
        exact: true,
        // element: <ApplicantProgramApplied />,
        element: isUniversityAdmin ? (
          <ApplicantProgramApplied />
        ) : (
          // <ProtectedRoute element={<ApplicantProgramApplied />} permissionName="applicant program applied" />
          <ProtectedRouteForDynamicPages
            element={<ApplicantProgramApplied />}
            permissionName="/applicantProgramApplied"
          />
        ),
      },
      {
        path: '/applicantProgramListing',
        name: 'applicantProgramListing',
        exact: true,
        // element: <ApplicantProgramListing />,
        element: isUniversityAdmin ? (
          <ApplicantProgramListing />
        ) : (
          // <ProtectedRoute element={<ApplicantProgramListing />} permissionName="admissions intake" />
          <ProtectedRouteForDynamicPages
            element={<ApplicantProgramListing />}
            permissionName="/applicantProgramListing"
          />
        ),
      },
      {
        path: '/addmissionintek',
        name: 'addmissionIntek',
        exact: true,
        // element: <AddmissionIntek />,
        element: isUniversityAdmin ? (
          <AddmissionIntek />
        ) : (
          // <ProtectedRoute element={<AddmissionIntek />} permissionName="admissions intake" />
          <ProtectedRouteForDynamicPages
            element={<AddmissionIntek />}
            permissionName="/addmissionintek"
          />
        ),
      },
      {
        path: '/agentSignup',
        name: 'agentSignup',
        exact: true,
        // element: <AgentSignup />,
        element: isUniversityAdmin ? (
          <AgentSignup />
        ) : (
          // <ProtectedRoute element={<AgentSignup />} permissionName="agent signup" />
          <ProtectedRouteForDynamicPages element={<AgentSignup />} permissionName="/agentSignup" />
        ),
      },

      {
        path: '/agentProfile',
        name: 'agentProfile',
        exact: true,
        // element: <AgentProfile />,
        element: isUniversityAdmin ? (
          <AgentProfile />
        ) : (
          // <ProtectedRoute element={<AgentProfile />} permissionName="agent signup" />
          <ProtectedRouteForDynamicPages
            element={<AgentProfile />}
            permissionName="/agentProfile"
          />
        ),
      },
      {
        path: '/changePassword',
        name: 'changePassword',
        exact: true,
        // element: <AgentProfile />,
        element: isUniversityAdmin ? (
          <ChangePassword />
        ) : (
          // <ProtectedRoute element={<ChangePassword />} permissionName="agent signup" />
          <ProtectedRouteForDynamicPages
            element={<ChangePassword />}
            permissionName="/changePassword"
          />
        ),
      },
      {
        path: '/university',
        name: 'university',
        exact: true,
        // element: <University />,
        element: isUniversityAdmin ? (
          <University />
        ) : (
          // <ProtectedRoute element={<University />} permissionName="manage universities" />
          <ProtectedRouteForDynamicPages element={<University />} permissionName="/university" />
        ),
      },
      {
        path: '/add-university',
        name: 'addUniversity',
        exact: true,
        // element: <AddUniversity />,
        element: isUniversityAdmin ? (
          <AddUniversity />
        ) : (
          // <ProtectedRoute element={<AddUniversity />} permissionName="add university" />
          <ProtectedRouteForDynamicPages
            element={<AddUniversity />}
            permissionName="/add-university"
          />
        ),
      },
      {
        path: '/ManageStructureMilestoneChecklists',
        name: 'ManageStructureMilestoneChecklists',
        exact: true,
        // element: <ManageStructureMilestoneChecklists />,
        element: isUniversityAdmin ? (
          <ManageStructureMilestoneChecklists />
        ) : (
          // <ProtectedRoute element={<ManageStructureMilestoneChecklists />} permissionName="manage structure milestone checklists" />
          <ProtectedRouteForDynamicPages
            element={<ManageStructureMilestoneChecklists />}
            permissionName="/ManageStructureMilestoneChecklists"
          />
        ),
      },

      {
        path: '/AddCourse',
        name: 'AddCourse',
        exact: true,
        // element: <AddCourse />,
        element: isUniversityAdmin ? (
          <AddCourse />
        ) : (
          // <ProtectedRoute element={<AddCourse />} permissionName="add university course" />
          <ProtectedRouteForDynamicPages element={<AddCourse />} permissionName="/AddCourse" />
        ),
      },

      {
        path: '/AddProgramCourse',
        name: 'AddProgramCourse',
        exact: true,
        // element: <AddProgramCourse />,
        element: isUniversityAdmin ? (
          <AddProgramCourse />
        ) : (
          // <ProtectedRoute element={<AddProgramCourse />} permissionName="add university course" />
          <ProtectedRouteForDynamicPages element={<AddProgramCourse />} permissionName="/AddProgramCourse" />
        ),
      },
      // ------------------->> UNIVERSITY PAGES ROUTE END <<----------------------------------

      { path: '/form-editor', name: 'form-editor', exact: true, element: <FormEditor /> },

      { path: '/tables/basic-table', name: 'basic-table', exact: true, element: <Basictable /> },
      {
        path: '/tables/react-table',
        name: 'react-table',
        exact: true,
        element: <CustomReactTable />,
      },
      {
        path: '/tables/data-table',
        name: 'data-table',
        exact: true,
        element: <ReactBootstrapTable />,
      },
      { path: '/charts/apex', name: 'apex', exact: true, element: <ApexCharts /> },
      { path: '/charts/chartjs', name: 'chartjs', exact: true, element: <ChartJs /> },
      { path: '/sample-pages/profile', name: 'profile', exact: true, element: <Profile /> },
      {
        path: '/sample-pages/helper-class',
        name: 'helper-class',
        exact: true,
        element: <HelperClass />,
      },
      {
        path: '/sample-pages/starterkit',
        name: 'starterkit',
        exact: true,
        element: <StarterKit />,
      },
      { path: '/sample-pages/gallery', name: 'gallery', exact: true, element: <Gallery /> },
      {
        path: '/sample-pages/search-result',
        name: 'search-result',
        exact: true,
        element: <SearchResult />,
      },
      { path: '/icons/bootstrap', name: 'bootstrap', exact: true, element: <Bootstrap /> },
      { path: '/icons/feather', name: 'feather', exact: true, element: <Feather /> },
      { path: '/map/vector', name: 'vector', exact: true, element: <CustomVectorMap /> },
      { path: '/widget', name: 'widget', exact: true, element: <Widget /> },
      { path: '/casl', name: 'casl', exact: true, element: <CASL /> },
      { path: '*', element: <Navigate to="/auth/404" /> },

      /////////////////////////SMR
      {
        path: '/form-application-steps',
        name: 'form-application-steps',
        exact: true,
        // element: <FormStepsMain />,
        element: isUniversityAdmin ? (
          <FormStepsMain />
        ) : (
          // <ProtectedRoute element={<FormStepsMain />} permissionName="profile" />
          <ProtectedRouteForDynamicPages
            element={<FormStepsMain />}
            permissionName="/form-application-steps"
          />
        ),
      },
      {
        path: '/TasksCommunicationsStudents',
        name: 'TasksCommunicationsStudents',
        exact: true,
        // element: <TasksCommunicationsStudents />,
        element: isUniversityAdmin ? (
          <TasksCommunicationsStudents />
        ) : (
          // <ProtectedRoute element={<TasksCommunicationsStudents />} permissionName="tasks communication" />
          <ProtectedRouteForDynamicPages
            element={<TasksCommunicationsStudents />}
            permissionName="/TasksCommunicationsStudents"
          />
        ),
      },
      {
        path: '/Courseplan',
        name: 'Courseplan',
        exact: true,
        // element: <Courseplan />,
        element: isUniversityAdmin ? (
          <Courseplan />
        ) : (
          // <ProtectedRoute element={<Courseplan />} permissionName="add university course" />
          <ProtectedRouteForDynamicPages element={<Courseplan />} permissionName="/Courseplan" />
        ),
      },
      {
        path: '/AddUni',
        name: 'AddUni',
        exact: true,
        // element: <AddUni />,
        element: isUniversityAdmin ? (
          <AddUni />
        ) : (
          // <ProtectedRoute element={<AddUni />} permissionName="add university" />
          <ProtectedRouteForDynamicPages element={<AddUni />} permissionName="/AddUni" />
        ),
      },
      {
        path: '/AddPrgm',
        name: 'AddPrgm',
        exact: true,
        // element: <AddPrgm />,
        element: isUniversityAdmin ? (
          <AddPrgm />
        ) : (
          // <ProtectedRoute element={<AddPrgm />} permissionName="add university programs" />
          <ProtectedRouteForDynamicPages element={<AddPrgm />} permissionName="/AddPrgm" />
        ),
      },
      {
        path: '/FormEditorOne',
        name: 'FormEditorOne',
        exact: true,
        // element: <FormEditorOne />,
        element: isUniversityAdmin ? (
          <FormEditorOne />
        ) : (
          // <ProtectedRoute element={<FormEditorOne />} permissionName="description" />
          <ProtectedRouteForDynamicPages
            element={<FormEditorOne />}
            permissionName="/FormEditorOne"
          />
        ),
      },
      {
        path: '/TasksCommunicationsSupervisor',
        name: 'TasksCommunicationsSupervisor',
        exact: true,
        // element: <TasksCommunicationsSupervisor />,
        element: isUniversityAdmin ? (
          <TasksCommunicationsSupervisor />
        ) : (
          // <ProtectedRoute element={<TasksCommunicationsSupervisor />} permissionName="research projects" />
          <ProtectedRouteForDynamicPages
            element={<TasksCommunicationsSupervisor />}
            permissionName="/TasksCommunicationsSupervisor"
          />
        ),
      },

      /////////////////////RESHMA
      {
        path: '/GradResearchLanguages',
        name: 'GradResearchLanguages',
        exact: true,
        // element: <GradResearchLanguages />,
        element: isUniversityAdmin ? (
          <GradResearchLanguages />
        ) : (
          // <ProtectedRoute element={<GradResearchLanguages />} permissionName="manage graduate research languages" />
          <ProtectedRouteForDynamicPages
            element={<GradResearchLanguages />}
            permissionName="/GradResearchLanguages"
          />
        ),
      },
      {
        path: '/EditResearch',
        name: 'EditResearch',
        exact: true,
        // element: <EditResearch />,
        element: isUniversityAdmin ? (
          <EditResearch />
        ) : (
          // <ProtectedRoute element={<EditResearch />} permissionName="edit research" />
          <ProtectedRouteForDynamicPages
            element={<EditResearch />}
            permissionName="/EditResearch"
          />
        ),
      },
      {
        path: '/AddResearch',
        name: 'AddResearch',
        exact: true,
        // element: <AddResearch />,
        element: isUniversityAdmin ? (
          <AddResearch />
        ) : (
          // <ProtectedRoute element={<AddResearch />} permissionName="add Research" />
          <ProtectedRouteForDynamicPages element={<AddResearch />} permissionName="/AddResearch" />
        ),
      },

      {
        path: '/ManageResearches',
        name: 'ManageResearches',
        exact: true,
        // element: <ManageResearches />,
        element: isUniversityAdmin ? (
          <ManageResearches />
        ) : (
          // <ProtectedRoute element={<ManageResearches />} permissionName="manage researches" />
          <ProtectedRouteForDynamicPages
            element={<ManageResearches />}
            permissionName="/ManageResearches"
          />
        ),
      },
      {
        path: '/Addgeneral',
        name: 'Addgeneral',
        exact: true,
        // element: <Addgeneral />,
        element: isUniversityAdmin ? (
          <Addgeneral />
        ) : (
          // <ProtectedRoute element={<Addgeneral />} permissionName="add applicant information" />
          <ProtectedRouteForDynamicPages element={<Addgeneral />} permissionName="/Addgeneral" />
        ),
      },
      {
        path: '/Attachmentadd',
        name: 'Attachmentadd',
        exact: true,
        // element: <Attachmentadd />,
        element: isUniversityAdmin ? (
          <Attachmentadd />
        ) : (
          // <ProtectedRoute element={<Attachmentadd />} permissionName="add applicant attachments" />
          <ProtectedRouteForDynamicPages
            element={<Attachmentadd />}
            permissionName="/Attachmentadd"
          />
        ),
      },
      {
        path: '/ManageAgenttwo',
        name: 'ManageAgenttwo',
        exact: true,
        // element: <ManageAgenttwo />,
        element: isUniversityAdmin ? (
          <ManageAgenttwo />
        ) : (
          // <ProtectedRoute element={<ManageAgenttwo />} permissionName="manage agents" />
          <ProtectedRouteForDynamicPages
            element={<ManageAgenttwo />}
            permissionName="/ManageAgenttwo"
          />
        ),
      },
      {
        path: '/Contactinformation',
        name: 'Contactinformation',
        exact: true,
        // element: <Contactinformation />,
        element: isUniversityAdmin ? (
          <Contactinformation />
        ) : (
          // <ProtectedRoute element={<Contactinformation />} permissionName="add applicant contact information" />
          <ProtectedRouteForDynamicPages
            element={<Contactinformation />}
            permissionName="/Contactinformation"
          />
        ),
      },
      {
        path: '/Experienceadd',
        name: 'Experienceadd',
        exact: true,
        // element: <Experienceadd />,
        element: isUniversityAdmin ? (
          <Experienceadd />
        ) : (
          // <ProtectedRoute element={<Experienceadd />} permissionName="add applicant experience" />
          <ProtectedRouteForDynamicPages
            element={<Experienceadd />}
            permissionName="/Experienceadd"
          />
        ),
      },
      {
        path: '/Honorsadd',
        name: 'Honorsadd',
        exact: true,
        // element: <Honorsadd />,
        element: isUniversityAdmin ? (
          <Honorsadd />
        ) : (
          // <ProtectedRoute element={<Honorsadd />} permissionName="add applicant award & honors" />
          <ProtectedRouteForDynamicPages element={<Honorsadd />} permissionName="/Honorsadd" />
        ),
      },
      {
        path: '/QAbanned',
        name: 'QAbanned',
        exact: true,
        // element: <QAbanned />,
        element: isUniversityAdmin ? (
          <QAbanned />
        ) : (
          // <ProtectedRoute element={<QAbanned />} permissionName="manage banned countries" />
          <ProtectedRouteForDynamicPages element={<QAbanned />} permissionName="/QAbanned" />
        ),
      },
      {
        path: '/Manageuserss',
        name: 'Manageuserss',
        exact: true,
        // element: <Manageuserss />,
        element: isUniversityAdmin ? (
          <Manageuserss />
        ) : (
          // <ProtectedRoute element={<Manageuserss />} permissionName="manage users" />
          <ProtectedRouteForDynamicPages
            element={<Manageuserss />}
            permissionName="/Manageuserss"
          />
        ),
      },
      {
        path: '/ManageBanAgent',
        name: 'ManageBanAgent',
        exact: true,
        // element: <ManageBanAgent />,
        element: isUniversityAdmin ? (
          <ManageBanAgent />
        ) : (
          // <ProtectedRoute element={<ManageBanAgent />} permissionName="manage ban agents" />
          <ProtectedRouteForDynamicPages
            element={<ManageBanAgent />}
            permissionName="/ManageBanAgent"
          />
        ),
      },
      {
        path: '/ListUsers',
        name: 'ListUsers',
        exact: true,
        // element: <ListUsers />,
        element: isUniversityAdmin ? (
          <ListUsers />
        ) : (
          // <ProtectedRoute element={<Listusers />} permissionName="manage users" />
          <ProtectedRouteForDynamicPages element={<ListUsers />} permissionName="/ListUsers" />
        ),
      },
      {
        path: '/Universityrestriction',
        name: 'Universityrestriction',
        exact: true,
        // element: <Universityrestriction />,
        element: isUniversityAdmin ? (
          <Universityrestriction />
        ) : (
          // <ProtectedRoute element={<Universityrestriction />} permissionName="manage university restrictions" />
          <ProtectedRouteForDynamicPages
            element={<Universityrestriction />}
            permissionName="/Universityrestriction"
          />
        ),
      },
      {
        path: '/ManageApplicantsearch',
        name: 'ManageApplicantsearch',
        exact: true,
        // element: <ManageApplicantsearch />,
        element: isUniversityAdmin ? (
          <ManageApplicantsearch />
        ) : (
          // <ProtectedRoute element={<ManageApplicantsearch />} permissionName="search agent" />
          <ProtectedRouteForDynamicPages
            element={<ManageApplicantsearch />}
            permissionName="/ManageApplicantsearch"
          />
        ),
      },
      {
        path: '/Agentprofilev2',
        name: 'Agentprofilev2',
        exact: true,
        // element: <Agentprofilev2 />,
        element: isUniversityAdmin ? (
          <Agentprofilev2 />
        ) : (
          // <ProtectedRoute element={<Agentprofilev2 />} permissionName="Agent Profile" />
          <ProtectedRouteForDynamicPages
            element={<Agentprofilev2 />}
            permissionName="/Agentprofilev2"
          />
        ),
      },
      {
        path: '/Languageadd',
        name: 'Languageadd',
        exact: true,
        // element: <Languageadd />,
        element: isUniversityAdmin ? (
          <Languageadd />
        ) : (
          // <ProtectedRoute element={<Languageadd />} permissionName="add applicant languages" />
          <ProtectedRouteForDynamicPages element={<Languageadd />} permissionName="/Languageadd" />
        ),
      },
      {
        path: '/MedicalInformation',
        name: 'MedicalInformation',
        exact: true,
        // element: <MedicalInformation />,
        element: isUniversityAdmin ? (
          <MedicalInformation />
        ) : (
          // <ProtectedRoute element={<MedicalInformation />} permissionName="add applicant medical information" />
          <ProtectedRouteForDynamicPages
            element={<MedicalInformation />}
            permissionName="/MedicalInformation"
          />
        ),
      },
      {
        path: '/Qualificationsadd',
        name: 'Qualificationsadd',
        exact: true,
        // element: <Qualificationsadd />,
        element: isUniversityAdmin ? (
          <Qualificationsadd />
        ) : (
          // <ProtectedRoute element={<Qualificationsadd />} permissionName="applicant qualifications" />
          <ProtectedRouteForDynamicPages
            element={<Qualificationsadd />}
            permissionName="/Qualificationsadd"
          />
        ),
      },
      {
        path: '/Referencesadd',
        name: 'Referencesadd',
        exact: true,
        // element: <Referencesadd />,
        element: isUniversityAdmin ? (
          <Referencesadd />
        ) : (
          // <ProtectedRoute element={<Referencesadd />} permissionName="add applicant refrences" />
          <ProtectedRouteForDynamicPages
            element={<Referencesadd />}
            permissionName="/Referencesadd"
          />
        ),
      },
      {
        path: '/VisaInformationn',
        name: 'VisaInformationn',
        exact: true,
        // element: <VisaInformationn />,
        element: isUniversityAdmin ? (
          <VisaInformationn />
        ) : (
          // <ProtectedRoute element={<VisaInformationn />} permissionName="add applicant visa information" />
          <ProtectedRouteForDynamicPages
            element={<VisaInformationn />}
            permissionName="/VisaInformationn"
          />
        ),
      },
      {
        path: '/GradTemplates',
        name: 'GradTemplates',
        exact: true,
        // element: <GradTemplates />,
        element: isUniversityAdmin ? (
          <GradTemplates />
        ) : (
          // <ProtectedRoute element={<GradTemplates />} permissionName="manage graduate templates" />
          <ProtectedRouteForDynamicPages
            element={<GradTemplates />}
            permissionName="/GradTemplates"
          />
        ),
      },
      {
        path: '/StructureMilestone',
        name: 'StructureMilestone',
        exact: true,
        // element: <StructureMilestone />,
        element: isUniversityAdmin ? (
          <StructureMilestone />
        ) : (
          // <ProtectedRoute element={<StructureMilestone />} permissionName="manage research structure milestones" />
          <ProtectedRouteForDynamicPages
            element={<StructureMilestone />}
            permissionName="/StructureMilestone"
          />
        ),
      },
      {
        path: '/ManageAgentLevelrates',
        name: 'ManageAgentLevelrates',
        exact: true,
        // element: <ManageAgentLevelrates />,
        element: isUniversityAdmin ? (
          <ManageAgentLevelrates />
        ) : (
          // <ProtectedRoute element={<ManageAgentLevelrates />} permissionName="manage agent level rates" />
          <ProtectedRouteForDynamicPages
            element={<ManageAgentLevelrates />}
            permissionName="/ManageAgentLevelrates"
          />
        ),
      },
      {
        path: '/ManageTransferss',
        name: 'ManageTransferss',
        exact: true,
        // element: <ManageTransferss />,
        element: isUniversityAdmin ? (
          <ManageTransferss />
        ) : (
          // <ProtectedRoute element={<ManageTransferss />} permissionName="transfers List" />
          <ProtectedRouteForDynamicPages
            element={<ManageTransferss />}
            permissionName="/ManageTransferss"
          />
        ),
      },
      {
        path: '/GradDefenceEvaluator',
        name: 'GradDefenceEvaluator',
        exact: true,
        // element: <GradDefenceEvaluator />,
        element: isUniversityAdmin ? (
          <GradDefenceEvaluator />
        ) : (
          // <ProtectedRoute element={<GradDefenceEvaluator />} permissionName="manage grad defense evaluators" />
          <ProtectedRouteForDynamicPages
            element={<GradDefenceEvaluator />}
            permissionName="/GradDefenceEvaluator"
          />
        ),
      },
      {
        path: '/ManageGradApplicationTypes',
        name: 'ManageGradApplicationTypes',
        exact: true,
        // element: <ManageGradApplicationTypes />,
        element: isUniversityAdmin ? (
          <ManageGradApplicationTypes />
        ) : (
          // <ProtectedRoute element={<ManageGradApplicationTypes />} permissionName="manage grad application types" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradApplicationTypes />}
            permissionName="/ManageGradApplicationTypes"
          />
        ),
      },
      {
        path: '/AddUser',
        name: 'AddUser',
        exact: true,
        // element: <AddUser />,
        element: isUniversityAdmin ? (
          <AddUser />
        ) : (
          // <ProtectedRoute element={<AddUserss />} permissionName="Manage Users" />
          <ProtectedRouteForDynamicPages element={<AddUser />} permissionName="/AddUser" />
        ),
      },
      {
        path: '/ManageGradActivityTypes',
        name: 'ManageGradActivityTypes',
        exact: true,
        // element: <ManageGradActivityTypes />,
        element: isUniversityAdmin ? (
          <ManageGradActivityTypes />
        ) : (
          // <ProtectedRoute element={<ManageGradActivityTypes />} permissionName="manage grad activity types" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradActivityTypes />}
            permissionName="/ManageGradActivityTypes"
          />
        ),
      },
      {
        path: '/GradResearchStatus',
        name: 'GradResearchStatus',
        exact: true,
        // element: <GradResearchStatus />,
        element: isUniversityAdmin ? (
          <GradResearchStatus />
        ) : (
          // <ProtectedRoute element={<GradResearchStatus />} permissionName="manage research statuses" />
          <ProtectedRouteForDynamicPages
            element={<GradResearchStatus />}
            permissionName="/GradResearchStatus"
          />
        ),
      },
      {
        path: '/AddPublications',
        name: 'AddPublications',
        exact: true,
        // element: <AddPublications />,
        element: isUniversityAdmin ? (
          <AddPublications />
        ) : (
          // <ProtectedRoute element={<AddPublications />} permissionName="add publications" />
          <ProtectedRouteForDynamicPages
            element={<AddPublications />}
            permissionName="/AddPublications"
          />
        ),
      },

      {
        path: '/Publications',
        name: 'Publications',
        exact: true,
        // element: <Publications />,
        element: isUniversityAdmin ? (
          <Publications />
        ) : (
          // <ProtectedRoute element={<Publications />} permissionName="manage publication" />
          <ProtectedRouteForDynamicPages
            element={<Publications />}
            permissionName="/Publications"
          />
        ),
      },
      {
        path: '/AddApplicationss',
        name: 'AddApplicationss',
        exact: true,
        // element: <AddApplicationss />,
        element: isUniversityAdmin ? (
          <AddApplicationss />
        ) : (
          // <ProtectedRoute element={<AddApplicationss />} permissionName="submit information" />
          <ProtectedRouteForDynamicPages
            element={<AddApplicationss />}
            permissionName="/AddApplicationss"
          />
        ),
      },
      {
        path: '/UniversityDetails',
        name: 'UniversityDetails',
        exact: true,
        // element: <UniversityDetails />,
        element: isUniversityAdmin ? (
          <UniversityDetails />
        ) : (
          // <ProtectedRoute element={<UniversityDetails />} permissionName="university details" />
          <ProtectedRouteForDynamicPages
            element={<UniversityDetails />}
            permissionName="/UniversityDetails"
          />
        ),
      },
      {
        path: '/ManageTransactionTypessss',
        name: 'ManageTransactionTypessss',
        exact: true,
        // element: <ManageTransactionTypessss />,
        element: isUniversityAdmin ? (
          <ManageTransactionTypessss />
        ) : (
          // <ProtectedRoute element={<ManageTransactionTypessss />} permissionName="manage transaction types" />
          <ProtectedRouteForDynamicPages
            element={<ManageTransactionTypessss />}
            permissionName="/ManageTransactionTypessss"
          />
        ),
      },
      {
        path: '/GradResearchAreas',
        name: 'GradResearchAreas',
        exact: true,
        // element: <GradResearchAreas />,
        element: isUniversityAdmin ? (
          <GradResearchAreas />
        ) : (
          // <ProtectedRoute element={<GradResearchAreas />} permissionName="manage research areas" />
          <ProtectedRouteForDynamicPages
            element={<GradResearchAreas />}
            permissionName="/GradResearchAreas"
          />
        ),
      },
      {
        path: '/SupervisorTypes',
        name: 'SupervisorTypes',
        exact: true,
        // element: <SupervisorTypes />,
        element: isUniversityAdmin ? (
          <SupervisorTypes />
        ) : (
          // <ProtectedRoute element={<SupervisorTypes />} permissionName="manage supervisor types" />
          <ProtectedRouteForDynamicPages
            element={<SupervisorTypes />}
            permissionName="/SupervisorTypes"
          />
        ),
      },
      {
        path: '/ManageAgenttypess',
        name: 'ManageAgenttypess',
        exact: true,
        // element: <ManageAgenttypess />,
        element: isUniversityAdmin ? (
          <ManageAgenttypess />
        ) : (
          // <ProtectedRoute element={<ManageAgenttypess />} permissionName="manage agent types" />
          <ProtectedRouteForDynamicPages
            element={<ManageAgenttypess />}
            permissionName="/ManageAgenttypess"
          />
        ),
      },
      {
        path: '/Agentapplicationself',
        name: 'Agentapplicationself',
        exact: true,
        // element: <Agentapplicationself />,
        element: isUniversityAdmin ? (
          <Agentapplicationself />
        ) : (
          // <ProtectedRoute element={<Agentapplicationself />} permissionName="agent application list" />
          <ProtectedRouteForDynamicPages
            element={<Agentapplicationself />}
            permissionName="/Agentapplicationself"
          />
        ),
      },
      {
        path: '/AgentappliRequest',
        name: 'AgentappliRequest',
        exact: true,
        // element: <AgentappliRequest />,
        element: isUniversityAdmin ? (
          <AgentappliRequest />
        ) : (
          // <ProtectedRoute element={<AgentappliRequest />} permissionName="manage application requests" />
          <ProtectedRouteForDynamicPages
            element={<AgentappliRequest />}
            permissionName="/AgentappliRequest"
          />
        ),
      },
      {
        path: '/Agentearning',
        name: 'Agentearning',
        exact: true,
        // element: <Agentearning />,
        element: isUniversityAdmin ? (
          <Agentearning />
        ) : (
          // <ProtectedRoute element={<Agentearning />} permissionName="{t("agentEarnings")}" />
          <ProtectedRouteForDynamicPages element={<Agentearning />} permissionName="/Agentearning" />
        ),
      },
      {
        path: '/UniversityRestrictionsss',
        name: 'UniversityRestrictionsss',
        exact: true,
        // element: <UniversityRestrictionsss />,
        element: isUniversityAdmin ? (
          <UniversityRestrictionsss />
        ) : (
          // <ProtectedRoute element={<UniversityRestrictionsss />} permissionName="manage university restrictions" />
          <ProtectedRouteForDynamicPages
            element={<UniversityRestrictionsss />}
            permissionName="/UniversityRestrictionsss"
          />
        ),
      },
      {
        path: '/Agentfavorites',
        name: 'Agentfavorites',
        exact: true,
        // element: <Agentfavorites />,
        element: isUniversityAdmin ? (
          <Agentfavorites />
        ) : (
          // <ProtectedRoute element={<Agentfavorites />} permissionName="manage agent favourites" />
          <ProtectedRouteForDynamicPages
            element={<Agentfavorites />}
            permissionName="/Agentfavorites"
          />
        ),
      },
      {
        path: '/AgentRating',
        name: 'AgentRating',
        exact: true,
        // element: <AgentRating />,
        element: isUniversityAdmin ? (
          <AgentRating />
        ) : (
          // <ProtectedRoute element={<AgentRating />} permissionName="manage agent ratings" />
          <ProtectedRouteForDynamicPages element={<AgentRating />} permissionName="/AgentRating" />
        ),
      },
      {
        path: '/ManageUserRatingsss',
        name: 'ManageUserRatingsss',
        exact: true,
        // element: <ManageUserRatingsss />,
        element: isUniversityAdmin ? (
          <ManageUserRatingsss />
        ) : (
          // <ProtectedRoute element={<ManageUserRatingsss />} permissionName="transfers list" />
          <ProtectedRouteForDynamicPages
            element={<ManageUserRatingsss />}
            permissionName="/ManageUserRatingsss"
          />
        ),
      },
      {
        path: '/AgentunivAppli',
        name: 'AgentunivAppli',
        exact: true,
        // element: <AgentunivAppli />,
        element: isUniversityAdmin ? (
          <AgentunivAppli />
        ) : (
          // <ProtectedRoute element={<AgentunivAppli />} permissionName="agent university applications list" />
          <ProtectedRouteForDynamicPages
            element={<AgentunivAppli />}
            permissionName="/AgentunivAppli"
          />
        ),
      },
      {
        path: '/Complaintagent',
        name: 'Complaintagent',
        exact: true,
        // element: <Complaintagent />,
        element: isUniversityAdmin ? (
          <Complaintagent />
        ) : (
          // <ProtectedRoute element={<Complaintagent />} permissionName="my reported complaints list" />
          <ProtectedRouteForDynamicPages
            element={<Complaintagent />}
            permissionName="/Complaintagent"
          />
        ),
      },
      {
        path: '/Tranferself',
        name: 'Tranferself',
        exact: true,
        // element: <Tranferself />,
        element: isUniversityAdmin ? (
          <Tranferself />
        ) : (
          // <ProtectedRoute element={<Tranferself />} permissionName="manage transfers" />
          <ProtectedRouteForDynamicPages element={<Tranferself />} permissionName="/Tranferself" />
        ),
      },
      {
        path: '/Transactionagentlist',
        name: 'Transactionagentlist',
        exact: true,
        // element: <Transactionagentlist />,
        element: isUniversityAdmin ? (
          <Transactionagentlist />
        ) : (
          // <ProtectedRoute element={<Transactionagentlist />} permissionName="transaction" />
          <ProtectedRouteForDynamicPages
            element={<Transactionagentlist />}
            permissionName="/Transactionagentlist"
          />
        ),
      },
      {
        path: '/UserNotifications',
        name: 'UserNotifications',
        exact: true,
        // element: <UserNotifications />,
        element: isUniversityAdmin ? (
          <UserNotifications />
        ) : (
          // <ProtectedRoute element={<UserNotifi />} permissionName="notification list" />
          <ProtectedRouteForDynamicPages
            element={<UserNotifications />}
            permissionName="/UserNotifications"
          />
        ),
      },
      {
        path: '/ManageCampusProgram',
        name: 'ManageCampusProgram',
        exact: true,
        // element: <ManageCampusProgram />,
        element: isUniversityAdmin ? (
          <ManageCampusProgram />
        ) : (
          // <ProtectedRoute element={<ManageCampusProgram />} permissionName="manage campus faculties" />
          <ProtectedRouteForDynamicPages
            element={<ManageCampusProgram />}
            permissionName="/ManageCampusProgram"
          />
        ),
      },
      {
        path: '/ApplicationInterface2',
        name: 'ApplicationInterface2',
        exact: true,
        // element: <ApplicationInterface2 />,
        element: isUniversityAdmin ? (
          <ApplicationInterface2 />
        ) : (
          // <ProtectedRoute element={<ApplicationInterface2 />} permissionName="manage grad application" />
          <ProtectedRouteForDynamicPages
            element={<ApplicationInterface2 />}
            permissionName="/ApplicationInterface2"
          />
        ),
      },
      {
        path: '/ManageUniversities',
        name: 'ManageUniversities',
        exact: true,
        // element: <ManageUniversities />,
        element: isUniversityAdmin ? (
          <ManageUniversities />
        ) : (
          // <ProtectedRoute element={<ManageUniversities />} permissionName="manage universities" />
          <ProtectedRouteForDynamicPages
            element={<ManageUniversities />}
            permissionName="/ManageUniversities"
          />
        ),
      },
      {
        path: '/GradResearchers',
        name: 'GradResearchers',
        exact: true,
        // element: <GradResearchers />,
        element: isUniversityAdmin ? (
          <GradResearchers />
        ) : (
          // <ProtectedRoute element={<GradResearchers />} permissionName="manage researchers" />
          <ProtectedRouteForDynamicPages
            element={<GradResearchers />}
            permissionName="/GradResearchers"
          />
        ),
      },
      {
        path: '/EditPublication',
        name: 'EditPublication',
        exact: true,
        // element: <EditPublication />,
        element: isUniversityAdmin ? (
          <EditPublication />
        ) : (
          // <ProtectedRoute element={<EditPublication />} permissionName="edit publications" />
          <ProtectedRouteForDynamicPages
            element={<EditPublication />}
            permissionName="/EditPublication"
          />
        ),
      },
      {
        path: '/GradeApplication1',
        name: 'GradeApplication1',
        exact: true,
        // element: <GradeApplication1 />,
        element: isUniversityAdmin ? (
          <GradeApplication1 />
        ) : (
          // <ProtectedRoute element={<GradeApplication1 />} permissionName="all applications" />
          <ProtectedRouteForDynamicPages
            element={<GradeApplication1 />}
            permissionName="/GradeApplication1"
          />
        ),
      },
      {
        path: '/dashboards/UniversitiesFile',
        name: 'UniversitiesFile',
        exact: true,
        // element: <UniversitiesFile />,
        element: isUniversityAdmin ? (
          <UniversitiesFile />
        ) : (
          // <ProtectedRoute element={<UniversitiesFile />} permissionName="universities file" />
          <ProtectedRouteForDynamicPages
            element={<UniversitiesFile />}
            permissionName="/dashboards/UniversitiesFile"
          />
        ),
      },

      {
        path: '/ManageCampus',
        name: 'ManageCampus',
        exact: true,
        // element: <ManageCampus />,
        element: isUniversityAdmin ? (
          <ManageCampus />
        ) : (
          // <ProtectedRoute element={<ManageCampus />} permissionName="manage campuses" />
          <ProtectedRouteForDynamicPages
            element={<ManageCampus />}
            permissionName="/ManageCampus"
          />
        ),
      },
      {
        path: '/ManageCampusFaculity',
        name: 'ManageCampusFaculity',
        exact: true,
        // element: <ManageCampusFaculity />,
        element: isUniversityAdmin ? (
          <ManageCampusFaculity />
        ) : (
          // <ProtectedRoute element={<ManageCampusFaculity />} permissionName="manage campus faculties" />
          <ProtectedRouteForDynamicPages
            element={<ManageCampusFaculity />}
            permissionName="/ManageCampusFaculity"
          />
        ),
      },
      {
        path: '/ManageFaculity',
        name: 'ManageFaculity',
        exact: true,
        // element: <ManageFaculity />,
        element: isUniversityAdmin ? (
          <ManageFaculity />
        ) : (
          // <ProtectedRoute element={<ManageFaculity />} permissionName="manage faculties" />
          <ProtectedRouteForDynamicPages
            element={<ManageFaculity />}
            permissionName="/ManageFaculity"
          />
        ),
      },
      {
        path: '/ResearchesStructure',
        name: 'ResearchesStructure',
        exact: true,
        // element: <ResearchesStructure />,
        element: isUniversityAdmin ? (
          <ResearchesStructure />
        ) : (
          // <ProtectedRoute element={<ResearchesStructure />} permissionName="manage research structures" />
          <ProtectedRouteForDynamicPages
            element={<ResearchesStructure />}
            permissionName="/ResearchesStructure"
          />
        ),
      },
      {
        path: '/ProgramDetails',
        name: 'ProgramDetails',
        exact: true,
        // element: <ProgramDetails />,
        element: isUniversityAdmin ? (
          <ProgramDetails />
        ) : (
          // <ProtectedRoute element={<ProgramDetails />} permissionName="program details" />
          <ProtectedRouteForDynamicPages
            element={<ProgramDetails />}
            permissionName="/ProgramDetails"
          />
        ),
      },
      {
        path: '/ManageProgram',
        name: 'ManageProgram',
        exact: true,
        // element: <ManageProgram />,
        element: isUniversityAdmin ? (
          <ManageProgram />
        ) : (
          // <ProtectedRoute element={<ManageProgram />} permissionName="manage programs" />
          <ProtectedRouteForDynamicPages
            element={<ManageProgram />}
            permissionName="/ManageProgram"
          />
        ),
      },

      {
        path: '/CampusDetails',
        name: 'CampusDetails',
        exact: true,
        // element: <CampusDetails />,
        element: isUniversityAdmin ? (
          <CampusDetails />
        ) : (
          // <ProtectedRoute element={<CampusDetails />} permissionName="campus details" />
          <ProtectedRouteForDynamicPages
            element={<CampusDetails />}
            permissionName="/CampusDetails"
          />
        ),
      },
      {
        path: '/ManageGradDefenseTypes',
        name: 'ManageGradDefenseTypes',
        exact: true,
        // element: <ManageGradDefenseTypes />,
        element: isUniversityAdmin ? (
          <ManageGradDefenseTypes />
        ) : (
          // <ProtectedRoute element={<ManageGradDefenseTypes />} permissionName="manage grad defense types" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradDefenseTypes />}
            permissionName="/ManageGradDefenseTypes"
          />
        ),
      },
      {
        path: '/ManageDefenseVenues',
        name: 'ManageDefenseVenues',
        exact: true,
        // element: <ManageDefenseVenues />,
        element: isUniversityAdmin ? (
          <ManageDefenseVenues />
        ) : (
          // <ProtectedRoute element={<ManageDefenseVenues />} permissionName="manage defense venues" />
          <ProtectedRouteForDynamicPages
            element={<ManageDefenseVenues />}
            permissionName="/ManageDefenseVenues"
          />
        ),
      },
      {
        path: '/ManageGradEvaluationRatings',
        name: 'ManageGradEvaluationRatings',
        exact: true,
        // element: <ManageGradEvaluationRatings />,
        element: isUniversityAdmin ? (
          <ManageGradEvaluationRatings />
        ) : (
          // <ProtectedRoute element={<ManageGradEvaluationRatings />} permissionName="manage grad evaluation ratings" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradEvaluationRatings />}
            permissionName="/ManageGradEvaluationRatings"
          />
        ),
      },
      {
        path: '/ManageGraduateIntakeStructures',
        name: 'ManageGraduateIntakeStructures',
        exact: true,
        // element: <ManageGraduateIntakeStructures />,
        element: isUniversityAdmin ? (
          <ManageGraduateIntakeStructures />
        ) : (
          // <ProtectedRoute element={<ManageGraduateIntakeStructures />} permissionName="manage graduate intake structures" />
          <ProtectedRouteForDynamicPages
            element={<ManageGraduateIntakeStructures />}
            permissionName="/ManageGraduateIntakeStructures"
          />
        ),
      },

      /////////////////NANDHU
      {
        path: '/ManageAgentLevels',
        name: 'ManageAgentLevels',
        exact: true,
        // element: <ManageAgentLevels />,
        element: isUniversityAdmin ? (
          <ManageAgentLevels />
        ) : (
          // <ProtectedRoute element={<ManageAgentLevelsss />} permissionName="manage agent types" />
          <ProtectedRouteForDynamicPages
            element={<ManageAgentLevels />}
            permissionName="/ManageAgentLevels"
          />
        ),
      },
      {
        path: '/Addcampusprgm',
        name: 'Addcampusprgm',
        exact: true,
        // element: <Addcampusprgm />,
        element: isUniversityAdmin ? (
          <Addcampusprgm />
        ) : (
          // <ProtectedRoute element={<Addcampusprgm />} permissionName="add campus program" />
          <ProtectedRouteForDynamicPages
            element={<Addcampusprgm />}
            permissionName="/Addcampusprgm"
          />
        ),
      },
      {
        path: '/Admincampusfaculty',
        name: 'Admincampusfaculty',
        exact: true,
        // element: <Admincampusfaculty />,
        element: isUniversityAdmin ? (
          <Admincampusfaculty />
        ) : (
          // <ProtectedRoute element={<Admincampusfaculty />} permissionName="add campus faculty" />
          <ProtectedRouteForDynamicPages
            element={<Admincampusfaculty />}
            permissionName="/Admincampusfaculty"
          />
        ),
      },
      {
        path: '/ManageComplaints',
        name: 'ManageComplaints',
        exact: true,
        // element: <ManageComplaints />,
        element: isUniversityAdmin ? (
          <ManageComplaints />
        ) : (
          // <ProtectedRoute element={<ManageComplaintsss />} permissionName="complaints list" />
          <ProtectedRouteForDynamicPages
            element={<ManageComplaints />}
            permissionName="/ManageComplaints"
          />
        ),
      },
      {
        path: '/Facultydetailss',
        name: 'Facultydetailss',
        exact: true,
        // element: <Facultydetailss />,
        element: isUniversityAdmin ? (
          <Facultydetailss />
        ) : (
          // <ProtectedRoute element={<Facultydetailss />} permissionName="faculty details" />
          <ProtectedRouteForDynamicPages
            element={<Facultydetailss />}
            permissionName="/Facultydetailss"
          />
        ),
      },
      {
        path: '/CampusFacultyDetails/:id',
        name: 'CampusFacultyDetails',
        exact: true,
        // element: <CampusFacultyDetails />,
        element: isUniversityAdmin ? (
          <CampusFacultyDetails />
        ) : (
          // <ProtectedRoute element={<CampusFacultyDetails />} permissionName="campus faculty details" />
          <ProtectedRouteForDynamicPages
            element={<CampusFacultyDetails />}
            permissionName="/CampusFacultyDetails"
          />
        ),
      },
      // {
      //   path: '/CampusFacultyDetails',
      //   name: 'CampusFacultyDetails',
      //   exact: true,
      //   element: <CampusFacultyDetails />,
      // },
      {
        path: '/ManageAgentApplication',
        name: 'ManageAgentApplication',
        exact: true,
        // element: <ManageAgentApplication />,
        element: isUniversityAdmin ? (
          <ManageAgentApplication />
        ) : (
          // <ProtectedRoute element={<ManageAgentApplicationss />} permissionName="agent applications list" />
          <ProtectedRouteForDynamicPages
            element={<ManageAgentApplication />}
            permissionName="/ManageAgentApplication"
          />
        ),
      },
      {
        path: '/Adminuniversity',
        name: 'Adminuniversity',
        exact: true,
        // element: <Adminuniversity />,
        element: isUniversityAdmin ? (
          <Adminuniversity />
        ) : (
          // <ProtectedRoute element={<Adminuniversity />} permissionName="add university" />
          <ProtectedRouteForDynamicPages
            element={<Adminuniversity />}
            permissionName="/Adminuniversity"
          />
        ),
      },
      {
        path: '/Adminfaculty',
        name: 'Adminfaculty',
        exact: true,
        // element: <Adminfaculty />,
        element: isUniversityAdmin ? (
          <Adminfaculty />
        ) : (
          // <ProtectedRoute element={<Adminfaculty />} permissionName="add university faculty" />
          <ProtectedRouteForDynamicPages
            element={<Adminfaculty />}
            permissionName="/Adminfaculty"
          />
        ),
      },
      {
        path: '/Admincampus',
        name: 'Admincampus',
        exact: true,
        // element: <Admincampus />,
        element: isUniversityAdmin ? (
          <Admincampus />
        ) : (
          // <ProtectedRoute element={<Admincampus />} permissionName="admin university campus" />
          <ProtectedRouteForDynamicPages element={<Admincampus />} permissionName="/Admincampus" />
        ),
      },
      {
        path: '/GradPublicationTypes',
        name: 'GradPublicationTypes',
        exact: true,
        // element: <GradPublicationTypes />,
        element: isUniversityAdmin ? (
          <GradPublicationTypes />
        ) : (
          // <ProtectedRoute element={<GradPublicationTypes />} permissionName="manage grad publication type" />
          <ProtectedRouteForDynamicPages
            element={<GradPublicationTypes />}
            permissionName="/GradPublicationTypes"
          />
        ),
      },
      {
        path: '/AddDefenseEvaluator',
        name: 'AddDefenseEvaluator',
        exact: true,
        // element: <AddDefenseEvaluator />,
        element: isUniversityAdmin ? (
          <AddDefenseEvaluator />
        ) : (
          // <ProtectedRoute element={<AddDefenseEvaluator />} permissionName="add defense evaluator" />
          <ProtectedRouteForDynamicPages
            element={<AddDefenseEvaluator />}
            permissionName="/AddDefenseEvaluator"
          />
        ),
      },
      {
        path: '/EditDefenseEvaluator',
        name: 'EditDefenseEvaluator',
        exact: true,
        // element: <EditDefenseEvaluator />,
        element: isUniversityAdmin ? (
          <EditDefenseEvaluator />
        ) : (
          // <ProtectedRoute element={<EditDefenseEvaluator />} permissionName="edit defense evaluator" />
          <ProtectedRouteForDynamicPages
            element={<EditDefenseEvaluator />}
            permissionName="/EditDefenseEvaluator"
          />
        ),
      },
      {
        path: '/Publication',
        name: 'Publication',
        exact: true,
        // element: <Publication />,
        element: isUniversityAdmin ? (
          <Publication />
        ) : (
          // <ProtectedRoute element={<Publication />} permissionName="manage publication" />
          <ProtectedRouteForDynamicPages element={<Publication />} permissionName="/Publication" />
        ),
      },
      {
        path: '/TasksStudents',
        name: 'TasksStudents',
        exact: true,
        // element: <TasksStudents />,
        element: isUniversityAdmin ? (
          <TasksStudents />
        ) : (
          // <ProtectedRoute element={<TasksStudents />} permissionName="tasks students" />
          <ProtectedRouteForDynamicPages
            element={<TasksStudents />}
            permissionName="/TasksStudents"
          />
        ),
      },
      {
        path: '/Tasksupervisor',
        name: 'Tasksupervisor',
        exact: true,
        // element: <Tasksupervisor />,
        element: isUniversityAdmin ? (
          <Tasksupervisor />
        ) : (
          // <ProtectedRoute element={<Tasksupervisor />} permissionName="task supervisor" />
          <ProtectedRouteForDynamicPages
            element={<Tasksupervisor />}
            permissionName="/Tasksupervisor"
          />
        ),
      },
      {
        path: '/AddTasksupervisor',
        name: 'AddTasksupervisor',
        exact: true,
        // element: <AddTasksupervisor />,
        element: isUniversityAdmin ? (
          <AddTasksupervisor />
        ) : (
          // <ProtectedRoute element={<AddTasksupervisor />} permissionName="add task" />
          <ProtectedRouteForDynamicPages
            element={<AddTasksupervisor />}
            permissionName="/AddTasksupervisor"
          />
        ),
      },

      {
        path: '/EditTaskSupervisor',
        name: 'EditTaskSupervisor',
        exact: true,
        // element: <EditTaskSupervisor />,
        element: isUniversityAdmin ? (
          <EditTaskSupervisor />
        ) : (
          // <ProtectedRoute element={<EditTaskSupervisor />} permissionName="edit task" />
          <ProtectedRouteForDynamicPages
            element={<EditTaskSupervisor />}
            permissionName="/EditTaskSupervisor"
          />
        ),
      },
      ///////////////////SHOBI
      {
        path: '/AddResearcher',
        name: 'AddResearcher',
        exact: true,
        // element: <AddResearcher />,
        element: isUniversityAdmin ? (
          <AddResearcher />
        ) : (
          // <ProtectedRoute element={<AddResearcher />} permissionName="add researcher" />
          <ProtectedRouteForDynamicPages
            element={<AddResearcher />}
            permissionName="/AddResearcher"
          />
        ),
      },
      {
        path: '/EditResearcher',
        name: 'EditResearcher',
        exact: true,
        // element: <EditResearcher />,
        element: isUniversityAdmin ? (
          <EditResearcher />
        ) : (
          // <ProtectedRoute element={<EditResearcher />} permissionName="edit researcher" />
          <ProtectedRouteForDynamicPages
            element={<EditResearcher />}
            permissionName="/EditResearcher"
          />
        ),
      },
      {
        path: '/Courses',
        name: 'Courses',
        exact: true,
        // element: <ProtectedRoute element={<Courses />} permissionName="manage course" />,
        element: isUniversityAdmin ? (
          <Courses />
        ) : (
          // <ProtectedRoute element={<Courses />} permissionName="manage course" />
          <ProtectedRouteForDynamicPages element={<Courses />} permissionName="/Courses" />
        ),
        // element: <Courses />,
      },
      {
        path: '/ManageCourseTypes',
        name: 'ManageCourseTypes',
        exact: true,
        // element: <ProtectedRoute element={<ManageCourseTypes />} permissionName="manage course type" />,
        element: isUniversityAdmin ? (
          <ManageCourseTypes />
        ) : (
          // <ProtectedRoute element={<ManageCourseTypes />} permissionName="manage course type" />
          <ProtectedRouteForDynamicPages
            element={<ManageCourseTypes />}
            permissionName="/ManageCourseTypes"
          />
        ),
        // element: <ManageCourseTypes />,
      },
      {
        path: '/EditCourse',
        name: 'EditCourse',
        exact: true,
        // element: <EditCourse />,
        element: isUniversityAdmin ? (
          <EditCourse />
        ) : (
          // <ProtectedRoute element={<EditCourse />} permissionName="edit course" />
          <ProtectedRouteForDynamicPages element={<EditCourse />} permissionName="/EditCourse" />
        ),
      },
      {
        path: '/EditProgramCourse',
        name: 'EditProgramCourse',
        exact: true,
        // element: <EditProgramCourse />,
        element: isUniversityAdmin ? (
          <EditProgramCourse />
        ) : (
          // <ProtectedRoute element={<EditProgramCourse />} permissionName="edit course" />
          <ProtectedRouteForDynamicPages element={<EditProgramCourse />} permissionName="/EditProgramCourse" />
        ),
      },
      {
        path: '/AddOfferedCourse',
        name: 'AddOfferedCourse',
        exact: true,
        // element: <AddOfferedCourse />,
        element: isUniversityAdmin ? (
          <AddOfferedCourse />
        ) : (
          // <ProtectedRoute element={<AddOfferedCourse />} permissionName="add offered course" />
          <ProtectedRouteForDynamicPages
            element={<AddOfferedCourse />}
            permissionName="/AddOfferedCourse"
          />
        ),
      },
      {
        path: '/EditOfferedCourse',
        name: 'EditOfferedCourse',
        exact: true,
        // element: <EditOfferedCourse />,
        element: isUniversityAdmin ? (
          <EditOfferedCourse />
        ) : (
          // <ProtectedRoute element={<EditOfferedCourse />} permissionName="edit offered course" />
          <ProtectedRouteForDynamicPages
            element={<EditOfferedCourse />}
            permissionName="/EditOfferedCourse"
          />
        ),
      },
      {
        path: '/GradDefenses',
        name: 'GradDefenses',
        exact: true,
        // element: <GradDefenses />,
        element: isUniversityAdmin ? (
          <GradDefenses />
        ) : (
          // <ProtectedRoute element={<GradDefenses />} permissionName="manage grad defenses" />
          <ProtectedRouteForDynamicPages
            element={<GradDefenses />}
            permissionName="/GradDefenses"
          />
        ),
      },
      {
        path: '/AddDefense',
        name: 'AddDefense',
        exact: true,
        // element: <AddDefense />,
        element: isUniversityAdmin ? (
          <AddDefense />
        ) : (
          // <ProtectedRoute element={<AddDefense />} permissionName="add defense information" />
          <ProtectedRouteForDynamicPages element={<AddDefense />} permissionName="/AddDefense" />
        ),
      },
      {
        path: '/EditDefense',
        name: 'EditDefense',
        exact: true,
        // element: <EditDefense />,
        element: isUniversityAdmin ? (
          <EditDefense />
        ) : (
          // <ProtectedRoute element={<EditDefense />} permissionName="update defense information" />
          <ProtectedRouteForDynamicPages element={<EditDefense />} permissionName="/EditDefense" />
        ),
      },

      {
        path: '/ProgramLevels',
        name: 'ProgramLevels',
        exact: true,
        // element: <ProgramLevels />,
        element: isUniversityAdmin ? (
          <ProgramLevels />
        ) : (
          // <ProtectedRoute element={<ProgramLevels />} permissionName="manage program levels" />
          <ProtectedRouteForDynamicPages
            element={<ProgramLevels />}
            permissionName="/ProgramLevels"
          />
        ),
      },
      {
        path: '/ProgramModes',
        name: 'ProgramModes',
        exact: true,
        // element: <ProgramModes />,
        element: isUniversityAdmin ? (
          <ProgramModes />
        ) : (
          // <ProtectedRoute element={<ProgramModes />} permissionName="manage program modes" />
          <ProtectedRouteForDynamicPages
            element={<ProgramModes />}
            permissionName="/ProgramModes"
          />
        ),
      },
      {
        path: '/Qualification',
        name: 'Qualification',
        exact: true,
        // element: <Qualification />,
        element: isUniversityAdmin ? (
          <Qualification />
        ) : (
          // <ProtectedRoute element={<Qualification />} permissionName="manage core qualifications" />
          <ProtectedRouteForDynamicPages
            element={<Qualification />}
            permissionName="/Qualification"
          />
        ),
      },
      {
        path: '/QualificationLevels',
        name: 'QualificationLevels',
        exact: true,
        // element: <QualificationLevels />,
        element: isUniversityAdmin ? (
          <QualificationLevels />
        ) : (
          // <ProtectedRoute element={<QualificationLevels />} permissionName="manage qualification levels" />
          <ProtectedRouteForDynamicPages
            element={<QualificationLevels />}
            permissionName="/QualificationLevels"
          />
        ),
      },
      {
        path: '/StudentType',
        name: 'StudentType',
        exact: true,
        // element: <StudentType />,
        element: isUniversityAdmin ? (
          <StudentType />
        ) : (
          // <ProtectedRoute element={<StudentType />} permissionName="manage student type" />
          <ProtectedRouteForDynamicPages element={<StudentType />} permissionName="/StudentType" />
        ),
      },
      {
        path: '/StudyType',
        name: 'StudyType',
        exact: true,
        // element: <StudyType />,
        element: isUniversityAdmin ? (
          <StudyType />
        ) : (
          // <ProtectedRoute element={<StudyType />} permissionName="manage study type" />
          <ProtectedRouteForDynamicPages element={<StudyType />} permissionName="/StudyType" />
        ),
      },
      {
        path: '/UniActivityType',
        name: 'UniActivityType',
        exact: true,
        // element: <UniActivityType />,
        element: isUniversityAdmin ? (
          <UniActivityType />
        ) : (
          // <ProtectedRoute element={<UniActivityType />} permissionName="manage university activity types" />
          <ProtectedRouteForDynamicPages
            element={<UniActivityType />}
            permissionName="/UniActivityType"
          />
        ),
      },
      {
        path: '/StdCourse',
        name: 'StdCourse',
        exact: true,
        // element: <StdCourse />,
        element: isUniversityAdmin ? (
          <UniActivityType />
        ) : (
          // <ProtectedRoute element={<UniActivityType />} permissionName="manage university activity types" />
          <ProtectedRouteForDynamicPages
            element={<UniActivityType />}
            permissionName="/StdCourse"
          />
        ),
      },
      {
        path: '/CourseReg',
        name: 'CourseReg',
        exact: true,
        // element: <CourseReg />,
        element: isUniversityAdmin ? (
          <CourseReg />
        ) : (
          // <ProtectedRoute element={<CourseReg />} permissionName="{t("registerCourses")}" />
          <ProtectedRouteForDynamicPages element={<CourseReg />} permissionName="/CourseReg" />
        ),
      },
      {
        path: '/StdCourseReg',
        name: 'StdCourseReg',
        exact: true,
        // element: <StdCourseReg />,
        element: isUniversityAdmin ? (
          <StdCourseReg />
        ) : (
          // <ProtectedRoute element={<StdCourseReg />} permissionName="add course registrations" />
          <ProtectedRouteForDynamicPages
            element={<StdCourseReg />}
            permissionName="/StdCourseReg"
          />
        ),
      },
      {
        path: '/ApplicationStatus',
        name: 'ApplicationStatus',
        exact: true,
        // element: <ApplicationStatus />,
        element: isUniversityAdmin ? (
          <ApplicationStatus />
        ) : (
          // <ProtectedRoute element={<ApplicationStatus />} permissionName="manage application statuses" />
          <ProtectedRouteForDynamicPages
            element={<ApplicationStatus />}
            permissionName="/ApplicationStatus"
          />
        ),
      },
      {
        path: '/VerificationStatus',
        name: 'VerificationStatus',
        exact: true,
        // element: <VerificationStatus />,
        element: isUniversityAdmin ? (
          <VerificationStatus />
        ) : (
          // <ProtectedRoute element={<VerificationStatus />} permissionName="manage verification statuses" />
          <ProtectedRouteForDynamicPages
            element={<VerificationStatus />}
            permissionName="/VerificationStatus"
          />
        ),
      },
      {
        path: '/Attachment',
        name: 'Attachment',
        exact: true,
        // element: <Attachment />,
        element: isUniversityAdmin ? (
          <Attachment />
        ) : (
          // <ProtectedRoute element={<Attachment />} permissionName="manage attachment type" />
          <ProtectedRouteForDynamicPages element={<Attachment />} permissionName="/Attachment" />
        ),
      },
      {
        path: '/ContactTypes',
        name: 'ContactTypes',
        exact: true,
        // element: <ContactTypes />,
        element: isUniversityAdmin ? (
          <ContactTypes />
        ) : (
          // <ProtectedRoute element={<ContactTypes />} permissionName="manage contact type" />
          <ProtectedRouteForDynamicPages
            element={<ContactTypes />}
            permissionName="/ContactTypes"
          />
        ),
      },
      {
        path: '/Currencies',
        name: 'Currencies',
        exact: true,
        // element: <Currencies />,
        element: isUniversityAdmin ? (
          <Currencies />
        ) : (
          // <ProtectedRoute element={<Currencies />} permissionName="manage currencies" />
          <ProtectedRouteForDynamicPages element={<Currencies />} permissionName="/Currencies" />
        ),
      },
      {
        path: '/LanguageProfiency',
        name: 'LanguageProfiency',
        exact: true,
        // element: <LanguageProfiency />,
        element: isUniversityAdmin ? (
          <LanguageProfiency />
        ) : (
          // <ProtectedRoute element={<LanguageProfiency />} permissionName="manage language proficiency" />
          <ProtectedRouteForDynamicPages
            element={<LanguageProfiency />}
            permissionName="/LanguageProfiency"
          />
        ),
      },
      {
        path: '/CoreLanguage',
        name: 'CoreLanguage',
        exact: true,
        // element: <CoreLanguage />,
        element: isUniversityAdmin ? (
          <CoreLanguage />
        ) : (
          // <ProtectedRoute element={<CoreLanguage />} permissionName="manage core languages" />
          <ProtectedRouteForDynamicPages
            element={<CoreLanguage />}
            permissionName="/CoreLanguage"
          />
        ),
      },
      {
        path: '/MaritalStatus',
        name: 'MaritalStatus',
        exact: true,
        // element: <MaritalStatus />,
        element: isUniversityAdmin ? (
          <MaritalStatus />
        ) : (
          // <ProtectedRoute element={<MaritalStatus />} permissionName="manage maritial status" />
          <ProtectedRouteForDynamicPages
            element={<MaritalStatus />}
            permissionName="/MaritalStatus"
          />
        ),
      },
      {
        path: '/PaymentModes',
        name: 'PaymentModes',
        exact: true,
        // element: <PaymentModes />,
        element: isUniversityAdmin ? (
          <PaymentModes />
        ) : (
          // <ProtectedRoute element={<PaymentModes />} permissionName="manage payment modes" />
          <ProtectedRouteForDynamicPages
            element={<PaymentModes />}
            permissionName="/PaymentModes"
          />
        ),
      },
      {
        path: '/Relegions',
        name: 'Relegions',
        exact: true,
        // element: <Relegions />,
        element: isUniversityAdmin ? (
          <Relegions />
        ) : (
          // <ProtectedRoute element={<Relegions />} permissionName="manage core religions" />
          <ProtectedRouteForDynamicPages element={<Relegions />} permissionName="/Relegions" />
        ),
      },
      {
        path: '/Countries',
        name: 'Countries',
        exact: true,
        // element: <Countries />,
        element: isUniversityAdmin ? (
          <Countries />
        ) : (
          // <ProtectedRoute element={<Countries />} permissionName="manage countries" />
          <ProtectedRouteForDynamicPages element={<Countries />} permissionName="/Countries" />
        ),
      },
      {
        path: '/States',
        name: 'States',
        exact: true,
        // element: <States />,
        element: isUniversityAdmin ? (
          <States />
        ) : (
          // <ProtectedRoute element={<States />} permissionName="manage states" />
          <ProtectedRouteForDynamicPages element={<States />} permissionName="/States" />
        ),
      },
      {
        path: '/Cities',
        name: 'Cities',
        exact: true,
        // element: <Cities />,
        element: isUniversityAdmin ? (
          <Cities />
        ) : (
          // <ProtectedRoute element={<Cities />} permissionName="manage cities" />
          <ProtectedRouteForDynamicPages element={<Cities />} permissionName="/Cities" />
        ),
      },
      {
        path: '/ManageOfferedCourse',
        name: 'ManageOfferedCourse',
        exact: true,
        // element: <ManageOfferedCourse />,
        element: isUniversityAdmin ? (
          <ManageOfferedCourse />
        ) : (
          // <ProtectedRoute element={<ManageOfferedCourse />} permissionName="manage offered courses" />
          <ProtectedRouteForDynamicPages
            element={<ManageOfferedCourse />}
            permissionName="/ManageOfferedCourse"
          />
        ),
      },
      {
        path: '/CourseByPlan',
        name: 'CourseByPlan',
        exact: true,
        // element: <CourseByPlan />,
        element: isUniversityAdmin ? (
          <CourseByPlan />
        ) : (
          // <ProtectedRoute element={<CourseByPlan />} permissionName="manage courses by plans" />
          <ProtectedRouteForDynamicPages element={<CourseByPlan />} permissionName="/CourseByPlan"/>
        ),
      },
      {
        path: '/ManagePreReq',
        name: 'ManagePreReq',
        exact: true,
        element: isUniversityAdmin ? (
          <ManagePreReq />
        ) : (
          <ProtectedRouteForDynamicPages element={<ManagePreReq />} permissionName="/ManagePreReq" />
        ),
      },
      {
        path: '/OfferedCoursesBySession',
        name: 'OfferedCoursesBySession',
        exact: true,
        element: isUniversityAdmin ? (
          <OfferedCoursesBySession />
        ) : (
          <ProtectedRouteForDynamicPages element={<OfferedCoursesBySession />} permissionName="/OfferedCoursesBySession" />
        ),
      },
      {
        path: '/ManagePreReq',
        name: 'ManagePreReq',
        exact: true,
        element: isUniversityAdmin ? (
          <ManagePreReq />
        ) : (
          <ProtectedRouteForDynamicPages element={<ManagePreReq />} permissionName="/ManagePreReq" />
        ),
      },
      {
        path: '/OfferedCoursesBySession',
        name: 'OfferedCoursesBySession',
        exact: true,
        element: isUniversityAdmin ? (
          <OfferedCoursesBySession />
        ) : (
          <ProtectedRouteForDynamicPages element={<OfferedCoursesBySession />} permissionName="/OfferedCoursesBySession" />
        ),
      },
      {
        path: '/ManageCoursePlan',
        name: 'ManageCoursePlan',
        exact: true,
        // element: <ManageCoursePlan />,
        element: isUniversityAdmin ? (
          <ManageCoursePlan />
        ) : (
          // <ProtectedRoute element={<ManageCoursePlan />} permissionName="manage courses plans" />
          <ProtectedRouteForDynamicPages element={<ManageCoursePlan />} permissionName="/ManageCoursePlan" />
        ),
      },
      {
        path: '/ManageAcadamicSession',
        name: 'ManageAcadamicSession',
        exact: true,
        // element: <ManageAcadamicSession />,
        element: isUniversityAdmin ? (
          <ManageAcadamicSession />
        ) : (
          // <ProtectedRoute element={<ManageAcadamicSession />} permissionName="manage acadamic session" />
          <ProtectedRouteForDynamicPages
            element={<ManageAcadamicSession />}
            permissionName="/ManageAcadamicSession"
          />
        ),
      },
      /////////////////ASHIQ
      {
        path: '/EditResearchActivities',
        name: 'EditResearchActivities',
        exact: true,
        // element: <EditResearchActivities />,
        element: isUniversityAdmin ? (
          <EditResearchActivities />
        ) : (
          // <ProtectedRoute element={<EditResearchActivities />} permissionName="edit research activities" />
          <ProtectedRouteForDynamicPages
            element={<EditResearchActivities />}
            permissionName="/EditResearchActivities"
          />
        ),
      },
      {
        path: '/ManageGradApplicationStatuses',
        name: 'ManageGradApplicationStatuses',
        exact: true,
        // element: <ManageGradApplicationStatuses />,
        element: isUniversityAdmin ? (
          <ManageGradApplicationStatuses />
        ) : (
          // <ProtectedRoute element={<ManageGradApplicationStatuses />} permissionName="manage grad application statuses" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradApplicationStatuses />}
            permissionName="/ManageGradApplicationStatuses"
          />
        ),
      },
      {
        path: '/ManageApplicationTypeChecklists',
        name: 'ManageApplicationTypeChecklists',
        exact: true,
        // element: <ManageApplicationTypeChecklists />,
        element: isUniversityAdmin ? (
          <ManageApplicationTypeChecklists />
        ) : (
          // <ProtectedRoute element={<ManageApplicationTypeChecklists />} permissionName="manage application type checklists" />
          <ProtectedRouteForDynamicPages
            element={<ManageApplicationTypeChecklists />}
            permissionName="/ManageApplicationTypeChecklists"
          />
        ),
      },
      {
        path: '/ManageResearchActivities',
        name: 'ManageResearchActivities',
        exact: true,
        // element: <ManageResearchActivities />,
        element: isUniversityAdmin ? (
          <ManageResearchActivities />
        ) : (
          // <ProtectedRoute element={<ManageResearchActivities />} permissionName="manage research activities" />
          <ProtectedRouteForDynamicPages
            element={<ManageResearchActivities />}
            permissionName="/ManageResearchActivities"
          />
        ),
      },
      {
        path: '/AddResearchActivities',
        name: 'AddResearchActivities',
        exact: true,
        // element: <AddResearchActivities />,
        element: isUniversityAdmin ? (
          <AddResearchActivities />
        ) : (
          // <ProtectedRoute element={<AddResearchActivities />} permissionName="add research activities" />
          <ProtectedRouteForDynamicPages
            element={<AddResearchActivities />}
            permissionName="/AddResearchActivities"
          />
        ),
      },

      {
        path: '/ManageModules',
        name: 'ManageModules',
        exact: true,
        // element: <ManageModules />,
        element: isUniversityAdmin ? (
          <ManageModules />
        ) : (
          // <ProtectedRoute element={<ManageModules />} permissionName="manage modules" />
          <ProtectedRouteForDynamicPages
            element={<ManageModules />}
            permissionName="/ManageModules"
          />
        ),
      },
      {
        path: '/ManageModulePages',
        name: 'ManageModulePages',
        exact: true,
        // element: <ManageModulePages />,
        element: isUniversityAdmin ? (
          <ManageModulePages />
        ) : (
          // <ProtectedRoute element={<ManageModulePages />} permissionName="manage modules pages" />
          <ProtectedRouteForDynamicPages
            element={<ManageModulePages />}
            permissionName="/ManageModulePages"
          />
        ),
      },
      {
        path: '/ManageGroupMenus',
        name: 'ManageGroupMenus',
        exact: true,
        // element: <ManageGroupMenus />,
        element: isUniversityAdmin ? (
          <ManageGroupMenus />
        ) : (
          // <ProtectedRoute element={<ManageGroupMenus />} permissionName="manage group menus" />
          <ProtectedRouteForDynamicPages
            element={<ManageGroupMenus />}
            permissionName="/ManageGroupMenus"
          />
        ),
      },
      {
        path: '/ManageGroupMenuLinks',
        name: 'ManageGroupMenuLinks',
        exact: true,
        // element: <ManageGroupMenuLinks />,
        element: isUniversityAdmin ? (
          <ManageGroupMenuLinks />
        ) : (
          // <ProtectedRoute element={<ManageGroupMenuLinks />} permissionName="manage group menu links" />
          <ProtectedRouteForDynamicPages
            element={<ManageGroupMenuLinks />}
            permissionName="/ManageGroupMenuLinks"
          />
        ),
      },
      {
        path: '/ManageUserInActiveMenu',
        name: 'ManageUserInActiveMenu',
        exact: true,
        // element: <ManageUserInActiveMenu />,
        element: isUniversityAdmin ? (
          <ManageUserInActiveMenu />
        ) : (
          // <ProtectedRoute element={<ManageUserInActiveMenu />} permissionName="manage user InActive menu" />
          <ProtectedRouteForDynamicPages
            element={<ManageUserInActiveMenu />}
            permissionName="/ManageUserInActiveMenu"
          />
        ),
      },
      {
        path: '/ManageUserInActiveLinks',
        name: 'ManageUserInActiveLinks',
        exact: true,
        // element: <ManageUserInActiveLinks />,
        element: isUniversityAdmin ? (
          <ManageUserInActiveLinks />
        ) : (
          // <ProtectedRoute element={<ManageUserInActiveLinks />} permissionName="manage user InActive links" />
          <ProtectedRouteForDynamicPages
            element={<ManageUserInActiveLinks />}
            permissionName="/ManageUserInActiveLinks"
          />
        ),
      },
      {
        path: '/AddModulePage',
        name: 'AddModulePage',
        exact: true,
        // element: <AddModulePage />,
        element: isUniversityAdmin ? (
          <AddModulePage />
        ) : (
          // <ProtectedRoute element={<AddModulePage />} permissionName="add module page" />
          <ProtectedRouteForDynamicPages
            element={<AddModulePage />}
            permissionName="/AddModulePage"
          />
        ),
      },
      {
        path: '/EditModulePage',
        name: 'EditModulePage',
        exact: true,
        // element: <EditModulePage />,
        element: isUniversityAdmin ? (
          <EditModulePage />
        ) : (
          // <ProtectedRoute element={<EditModulePage />} permissionName="edit module page" />
          <ProtectedRouteForDynamicPages
            element={<EditModulePage />}
            permissionName="/EditModulePage"
          />
        ),
      },
      {
        path: '/AddGroupMenuLinks',
        name: 'AddGroupMenuLinks',
        exact: true,
        // element: <AddGroupMenuLinks />,
        element: isUniversityAdmin ? (
          <AddGroupMenuLinks />
        ) : (
          // <ProtectedRoute element={<AddGroupMenuLinks />} permissionName="add group menu links" />
          <ProtectedRouteForDynamicPages
            element={<AddGroupMenuLinks />}
            permissionName="/AddGroupMenuLinks"
          />
        ),
      },
      {
        path: '/EditGroupMenuLinks',
        name: 'EditGroupMenuLinks',
        exact: true,
        // element: <EditGroupMenuLinks />,
        element: isUniversityAdmin ? (
          <EditGroupMenuLinks />
        ) : (
          // <ProtectedRoute element={<EditGroupMenuLinks />} permissionName="edit group menu links" />
          <ProtectedRouteForDynamicPages
            element={<EditGroupMenuLinks />}
            permissionName="/EditGroupMenuLinks"
          />
        ),
      },
      {
        path: '/CourseMarksScheme',
        name: 'CourseMarksScheme',
        exact: true,
        // element: <CourseMarksScheme />,
        element: isUniversityAdmin ? (
          <CourseMarksScheme />
        ) : (
          // <ProtectedRoute element={<CourseMarksScheme />} permissionName="manage course marks schemes" />
          <ProtectedRouteForDynamicPages
            element={<CourseMarksScheme />}
            permissionName="/CourseMarksScheme"
          />
        ),
      },
      // {
      //   path: '/ManageOfferedCourses',
      //   name: 'ManageOfferedCourses',
      //   exact: true,
      //   // element: <ManageOfferedCourses />,
      //   element: isUniversityAdmin ? (
      //     <ManageOfferedCourses />
      //   ) : (
      //     // <ProtectedRoute element={<ManageOfferedCourses />} permissionName="manage offered courses" />
      //     <ProtectedRouteForDynamicPages element={<ManageOfferedCourses />} permissionName="/ManageOfferedCourses" />
      //   ),
      // },
      {
        path: '/SummaryOfCurrentRegistrations',
        name: 'SummaryOfCurrentRegistrations',
        exact: true,
        // element: <SummaryOfCurrentRegistrations />,
        element: isUniversityAdmin ? (
          <SummaryOfCurrentRegistrations />
        ) : (
          // <ProtectedRoute element={<SummaryOfCurrentRegistrations />} permissionName="summary of current registrations" />
          <ProtectedRouteForDynamicPages
            element={<SummaryOfCurrentRegistrations />}
            permissionName="/SummaryOfCurrentRegistrations"
          />
        ),
      },
      {
        path: '/ManageGradAuthorRole',
        name: 'ManageGradAuthorRole',
        exact: true,
        // element: <ManageGradAuthorRole />,
        element: isUniversityAdmin ? (
          <ManageGradAuthorRole />
        ) : (
          // <ProtectedRoute element={<ManageGradAuthorRole />} permissionName="manage grad author role" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradAuthorRole />}
            permissionName="/ManageGradAuthorRole"
          />
        ),
      },
      {
        path: '/ManageGradDefenseStatuses',
        name: 'ManageGradDefenseStatuses',
        exact: true,
        // element: <ManageGradDefenseStatuses />,
        element: isUniversityAdmin ? (
          <ManageGradDefenseStatuses />
        ) : (
          // <ProtectedRoute element={<ManageGradDefenseStatuses />} permissionName="manage grad defense statuses" />
          <ProtectedRouteForDynamicPages
            element={<ManageGradDefenseStatuses />}
            permissionName="/ManageGradDefenseStatuses"
          />
        ),
      },
      ////////////////THUSHAR
      {
        path: '/UniversityManagementzzz',
        name: 'UniversityManagementzzz',
        exact: true,
        // element: <UniversityManagementzzz />,
        element: isUniversityAdmin ? (
          <UniversityManagementzzz />
        ) : (
          // <ProtectedRoute element={<UniversityManagementzzz />} permissionName="university management" />
          <ProtectedRouteForDynamicPages
            element={<UniversityManagementzzz />}
            permissionName="/UniversityManagementzzz"
          />
        ),
      },
      {
        path: '/ProgramDataDetails',
        name: 'ProgramDataDetails',
        exact: true,
        // element: <ProgramDataDetails />,
        element: isUniversityAdmin ? (
          <ProgramDataDetails />
        ) : (
          // <ProtectedRoute element={<ProgramDataDetails />} permissionName="program details" />
          <ProtectedRouteForDynamicPages
            element={<ProgramDataDetails />}
            permissionName="/ProgramDataDetails"
          />
        ),
      },
      {
        path: '/ManageApplicationTypeChecklists',
        name: 'ManageApplicationTypeChecklists',
        exact: true,
        // element: <ManageApplicationTypeChecklists />,
        element: isUniversityAdmin ? (
          <ManageApplicationTypeChecklists />
        ) : (
          // <ProtectedRoute element={<ManageApplicationTypeChecklists />} permissionName="manage application type checklists" />
          <ProtectedRouteForDynamicPages
            element={<ManageApplicationTypeChecklists />}
            permissionName="/ManageApplicationTypeChecklists"
          />
        ),
      },
      {
        path: '/ApplicantProgramApp',
        name: 'ApplicantProgramApp',
        exact: true,
        // element: <ApplicantProgramApp />,
        element: isUniversityAdmin ? (
          <ApplicantProgramApp />
        ) : (
          // <ProtectedRoute element={<applicantProgramApp />} permissionName="applicant program app" />
          <ProtectedRouteForDynamicPages
            element={<ApplicantProgramApp />}
            permissionName="/ApplicantProgramApp"
          />
        ),
      },

      {
        path: '/AdimissionIntake',
        name: 'AdimissionIntake',
        exact: true,
        // element: <AdimissionIntake />,
        element: isUniversityAdmin ? (
          <AdimissionIntake />
        ) : (
          // <ProtectedRoute element={<AdimissionIntake />} permissionName="admissions intake" />
          <ProtectedRouteForDynamicPages
            element={<AdimissionIntake />}
            permissionName="/AdimissionIntake"
          />
        ),
      },

      {
        path: '/addAdmissionIntake',
        name: 'addAdmissionIntake',
        exact: true,
        element: isUniversityAdmin ? (
          <AddAdmissionIntake />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<AddAdmissionIntake />}
            permissionName="/addAdmissionIntake"
          />
        ),
      },
      {
        path: '/admissionActivityTypes',
        name: 'admissionActivityTypes',
        exact: true,
        element: isUniversityAdmin ? (
          <AdmissionActivityTypes />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<AdmissionActivityTypes />}
            permissionName="/admissionActivityTypes"
          />
        ),
      },
      {
        path: '/intakeActivities',
        name: 'intakeActivities',
        exact: true,
        element: isUniversityAdmin ? (
          <IntakeActivities />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<IntakeActivities />}
            permissionName="/intakeActivities"
          />
        ),
      },
      {
        path: '/intakePrograms',
        name: 'intakePrograms',
        exact: true,
        element: isUniversityAdmin ? (
          <IntakePrograms />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<IntakePrograms />}
            permissionName="/intakePrograms"
          />
        ),
      },
      {
        path: '/programQualifications',
        name: 'programQualifications',
        exact: true,
        element: isUniversityAdmin ? (
          <ProgramQualifications />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProgramQualifications />}
            permissionName="/programQualifications"
          />
        ),
      },
      {
        path: '/programEligibility',
        name: 'programEligibility',
        exact: true,
        element: isUniversityAdmin ? (
          <ProgramEligibility />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProgramEligibility />}
            permissionName="/programEligibility"
          />
        ),
      },
      {
        path: '/programAttachments',
        name: 'programAttachments',
        exact: true,
        element: isUniversityAdmin ? (
          <ProgramAttachments />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProgramAttachments />}
            permissionName="/programAttachments"
          />
        ),
      },
      {
        path: '/processingStatus',
        name: 'processingStatus',
        exact: true,
        element: isUniversityAdmin ? (
          <ProcessingStatus />
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProcessingStatus />}
            permissionName="/processingStatus"
          />
        ),
      },
      { 
        path: '/processingSteps', 
        name: 'processingSteps', 
        exact: true,
        element: isUniversityAdmin ? (
          <ProcessingSteps/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProcessingSteps/>}
            permissionName="/processingSteps"
          />
        ), 
      },
      { 
        path: '/programProcessingSteps', 
        name: 'programProcessingSteps', 
        exact: true,
        element: isUniversityAdmin ? (
          <ProgramProcessingSteps/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ProgramProcessingSteps/>}
            permissionName="/progamProcessingSteps"
          />
        ), 
      },
      { 
        path: '/userRoleProcessingSteps', 
        name: 'userRoleProcessingSteps', 
        exact: true,
        element: isUniversityAdmin ? (
          <UserRoleProcessingSteps/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<UserRoleProcessingSteps/>}
            permissionName="/userRoleProcessingSteps"
          />
        ), 
      },
      { 
        path: '/applicationStatuses', 
        name: 'applicationStatuses', 
        exact: true,
        element: isUniversityAdmin ? (
          <ApplicationStatuses/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<ApplicationStatuses/>}
            permissionName="/applicationStatuses"
          />
        ), 
      },
      { 
        path: '/oaFeePaymentModes', 
        name: 'oaFeePaymentModes', 
        exact: true,
        element: isUniversityAdmin ? (
          <OAFeePaymentModes/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<OAFeePaymentModes/>}
            permissionName="/paymentModes"
          />
        ), 
      },
      { 
        path: '/feeTypes', 
        name: 'feeTypes', 
        exact: true,
        element: isUniversityAdmin ? (
          <FeeTypes/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<FeeTypes/>}
            permissionName="/feeTypes"
          />
        ), 
      },
      { 
        path: '/feeStatus', 
        name: 'feeStatus', 
        exact: true,
        element: isUniversityAdmin ? (
          <FeeStatus/>
        ) : (
          <ProtectedRouteForDynamicPages
            element={<FeeStatus/>}
            permissionName="/feeStatus"
          />
        ), 
      },


    ],
  },

  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },

      {
        path: 'login',
        element: localStorage.getItem('logedIn') ? (
          <Navigate to="/dashboards/minimal" />
        ) : (
          <Login />
        ),
      },
      {
        path: 'signup',
        element: localStorage.getItem('logedIn') ? (
          <Navigate to="/dashboards/minimal" />
        ) : (
          <Signup />
        ),
      },

      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'loginformik', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
  {
    path: '/admission',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      {
        path: 'login',
        element: (() => {
          const isLoggedIn = localStorage.getItem('logedIn');

          if (isLoggedIn) {
            if (userRoleAgent === 'agent') {
              <Navigate to="/agentProfile" />;
            } else {
              return <Navigate to="/applicantProfile" />;
            }
          }

          return <AdmissionLogin />;
        })(),
      },
      {
        path: 'signup',
        element: (() => {
          const isLoggedIn = localStorage.getItem('logedIn');

          if (isLoggedIn) {
            if (userRoleAgent === 'agent') {
              <Navigate to="/agentProfile" />;
            } else {
              return <Navigate to="/applicantProfile" />;
            }
          }

          return <AdmissionSignup />;
        })(),
      },
      { path: '*', element: <Navigate to="/admission/404" replace /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'loginformik', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default ThemeRoutes;
