import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en', // Default language is English
};

export const LanguageSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    // Corrected typo in the action name
    setLanguage: (state, action) => {
      state.language = action.payload; // Update the language in the state
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
