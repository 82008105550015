import React, { useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Button, Pagination } from 'react-bootstrap';
import * as html2pdf from 'html2pdf.js';
import Papa from 'papaparse';
import { Label, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { PiCaretUpDownFill } from 'react-icons/pi';
import * as XLSX from 'xlsx';

const CommonDataTable = (prop) => {
  const { t } = useTranslation();
  const { columns, data } = prop;

  const tableRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sorting, setSorting] = useState([]);
  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    return data.filter((row) => {
      const searchLower = searchTerm.toLowerCase();
      if (row.university?.name?.toLowerCase().includes(searchLower)) {
        return true;
      }
      return Object.values(row).some((value) =>
        value && String(value).toLowerCase().includes(searchLower)
      );
    });
  }, [searchTerm, data]);
  const dataWithSerial = useMemo(
    () =>
      filteredData.map((item, index) => ({
        serial: index + 1,
        ...item,
      })),
    [filteredData],
  );
  const table = useReactTable({
    data: dataWithSerial,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const parseDescriptionText = (description) => {
    try {
      const parsedDescription = JSON.parse(description);
      if (parsedDescription && parsedDescription.blocks) {
        return parsedDescription.blocks.map((block) => block.text).join('\n');
      }
    } catch (e) {
      return '';
    }
    return '';
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDownloadPDF = () => {
    const loadingIndicator = document.createElement('div');
    loadingIndicator.id = 'loading-indicator';
    loadingIndicator.textContent = 'Generating PDF, please wait...';
    loadingIndicator.style.position = 'fixed';
    loadingIndicator.style.top = '50%';
    loadingIndicator.style.left = '50%';
    loadingIndicator.style.transform = 'translate(-50%, -50%)';
    loadingIndicator.style.zIndex = '1000';
    loadingIndicator.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
    loadingIndicator.style.color = 'white';
    loadingIndicator.style.padding = '20px';
    loadingIndicator.style.borderRadius = '8px';
    loadingIndicator.style.fontSize = '16px';
    loadingIndicator.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)';
    document.body.appendChild(loadingIndicator);
    setTimeout(() => {
      const element = document.getElementById('table-container');
      const pageTitle =
        document.querySelector('.page-wrapper > .card > .card-header > h4')?.innerText ||
        document.querySelector('.page-wrapper > .card > .card-body > h4')?.innerText ||
        'DataTable';
      const tableColumns = element.querySelectorAll('th').length;
      const clonedTable = element.cloneNode(true);

      const tableHeaders = clonedTable.querySelectorAll('th');
      const headerNames = Array.from(tableHeaders).map((th) => th.textContent.trim().toLowerCase());

      const actionColumnIndex = headerNames.indexOf('action');

      if (actionColumnIndex !== -1) {
        tableHeaders[actionColumnIndex].remove();

        const rows = clonedTable.querySelectorAll('tr');
        rows.forEach((row) => {
          const cells = row.querySelectorAll('td');
          if (cells[actionColumnIndex]) {
            cells[actionColumnIndex].remove();
          }
        });
      }

      const wrapper = document.createElement('div');
      const heading = document.createElement('h1');
      heading.textContent = pageTitle;
      heading.style.textAlign = 'center';
      heading.style.fontSize = '32px';
      heading.style.fontWeight = 'bold';
      heading.style.marginBottom = '20px';

      let scale = 1;
      let pageSize = 'letter';
      let orientation = 'portrait';

      if (tableColumns > 15) {
        pageSize = 'A2';
        scale = 1;
        orientation = 'landscape';
        clonedTable.style.transform = `scale(${scale})`;
        clonedTable.style.transformOrigin = 'top center';
      } else if (tableColumns > 10) {
        pageSize = 'A3';
        scale = 1; // Adjusted to increase content size for larger tables
        orientation = 'landscape';
        clonedTable.style.transform = `scale(${scale})`;
        clonedTable.style.transformOrigin = 'top center';
      }

      // Add heading and table to the wrapper
      wrapper.appendChild(heading);
      wrapper.appendChild(clonedTable);

      // Set pdf generation options
      const opt = {
        margin: [15, 15],
        filename: `${pageTitle}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          unit: 'pt',
          format: pageSize,
          orientation,
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      // Generate and save the PDF
      html2pdf()
        .from(wrapper)
        .set(opt)
        .save()
        .finally(() => {
          document.body.removeChild(loadingIndicator); 
        });
    }, 300); 
  };

  const handleDownloadCSV = () => {
    const pageTitle =
      document.querySelector('.page-wrapper > .card > .card-header > h4')?.innerText ||
      document.querySelector('.page-wrapper > .card > .card-body > h4')?.innerText ||
      'DataTable';

    const headers = table
      .getHeaderGroups()
      .flatMap((headerGroup) =>
        headerGroup.headers.map((header) => header.column.columnDef.header || header.id)
        .filter((header) => header.toLowerCase() !== 'action')
      );

      const rows = table.getRowModel().rows.map((row) =>
        row.getVisibleCells().reduce((acc, cell) => {
          if (cell.column.id === 'action') {
            return acc;
          }
    
          let cellValue = cell.getValue();
    
          if (cell.column.id === 'is_active') {
            cellValue = cellValue ? 'Active' : 'Inactive';
          }
          if (cell.column.id === 'image') {
            cellValue = cellValue ? `Image URL: ${cellValue}` : '';
          } else if (
            typeof cellValue === 'string' &&
            cellValue.includes('{"blocks":') &&
            cellValue.includes('"text":')
          ) {
            cellValue = parseDescriptionText(cellValue);
          }
    
          acc.push(cellValue);
          return acc;
        }, [])
      );
    
    const csv = Papa.unparse({
      fields: headers,
      data: rows,
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${pageTitle}.csv`;
    link.click();
  };

  const handlePrintTable = async () => {
    const pageTitle =
      document.querySelector('.page-wrapper > .card > .card-header > h4')?.innerText ||
      document.querySelector('.page-wrapper > .card > .card-body > h4')?.innerText ||
      'DataTable';
  
    const element = document.getElementById('table-container');
    if (!element) {
      console.error('Table container not found!');
      return;
    }
  
    // Clone the table to preserve the original styling
    const clonedTable = element.cloneNode(true);

    // Remove the "Action" column and its corresponding cells
    const actionColumnIndex = Array.from(clonedTable.querySelectorAll('th'))
    .findIndex((th) => th.textContent.trim().toLowerCase() === 'action',
    );

    if (actionColumnIndex > -1) {
      // Remove the header for the "Action" column
      clonedTable.querySelectorAll('th')[actionColumnIndex]?.remove();

      // Remove corresponding cells in each row
      clonedTable.querySelectorAll('tr').forEach((row) => {
        const cells = row.querySelectorAll('td, th');
        if (cells[actionColumnIndex]) {
          cells[actionColumnIndex].remove();
        }
      });
    }

  
    // Ensure all images are loaded and converted to Base64
    const images = clonedTable.querySelectorAll('img');
    await Promise.all(
      Array.from(images).map(
        (img) =>
          new Promise((resolve) => {
            const handleImageLoad = () => {
              if (img.src && !img.src.startsWith('data:')) {
                fetch(img.src)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                      img.src = reader.result; // Replace with Base64 data URL
                      resolve();
                    };
                    reader.readAsDataURL(blob);
                  })
                  .catch(() => {
                    console.warn(`Failed to convert image to Base64: ${img.src}`);
                    resolve(); // Continue even if conversion fails
                  });
              } else {
                resolve(); // Image is already loaded or in Base64
              }
            };
  
            if (img.complete && img.naturalWidth > 0) {
              handleImageLoad();
            } else {
              img.onload = handleImageLoad;
              img.onerror = () => {
                console.warn(`Image failed to load: ${img.src}`);
                resolve(); // Continue even if an image fails to load
              };
            }
          }),
      ),
    );
  
    // Determine the number of columns in the table
    const tableColumns = element.querySelectorAll('th').length;
  
    // Adjust scaling based on the number of columns
    let scale = 2; // Default scale
    if (tableColumns > 15) {
      scale = 0.7; // Scale down for very wide tables
    } else if (tableColumns > 10) {
      scale = 0.8; // Slightly scale down for moderately wide tables
    }
  
    // Wrap the cloned table in a scaled container
    const wrapper = document.createElement('div');
    wrapper.style.transform = `scale(${scale})`;
    wrapper.style.transformOrigin = 'top left';
    wrapper.style.margin = 'auto'; // Center align the content
    wrapper.style.width = `${100 / scale}%`; // Adjust width to compensate for scaling
    wrapper.appendChild(clonedTable);
  
    // Create the print content
    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${pageTitle}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0 10px;
              padding: 0;
              background: white;
            }
            h1 {
              text-align: center;
              margin-bottom: 20px;
            }
            #print-wrapper {
              width: 95%;
              margin: 0 auto;
              overflow: hidden;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          </style>
        </head>
        <body>
          <div id="print-wrapper">
            <h1>${pageTitle}</h1>
            ${wrapper.innerHTML}
          </div>
        </body>
      </html>
    `;
  
    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (!printWindow) {
      console.error('Failed to open a new window for printing.');
      return;
    }
  
    // Write the content to the new window
    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  
    // Trigger the print dialog after content is fully loaded
    printWindow.onload = () => {
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
  
        // Automatically close the window after printing
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      }, 500); // Add a slight delay to ensure rendering
    };
    setTimeout(() => {
      if (!printWindow.closed) {
        printWindow.close();
      }
    }, 3000);
  };
  const handleDownloadExcel = () => {
    const pageTitle =
      document.querySelector('.page-wrapper > .card > .card-header > h4')?.innerText ||
      document.querySelector('.page-wrapper > .card > .card-body > h4')?.innerText ||
      'DataTable';
  
    const headers = table
      .getHeaderGroups()
      .flatMap((headerGroup) =>
        headerGroup.headers.map((header) => header.column.columnDef.header || header.id)
        .filter((header) => header.toLowerCase() !== 'action')
      );
  
    const rows = table.getRowModel().rows.map((row) =>
      row.getVisibleCells().reduce((acc, cell) => {
        if (cell.column.id === 'action') {
          return acc;
        }
  
        let cellValue = cell.getValue();
  
        if (cell.column.id === 'is_active') {
          cellValue = cellValue ? 'Active' : 'Inactive';
        }
        if (cell.column.id === 'image') {
          cellValue = cellValue ? `Image URL: ${cellValue}` : '';
        } else if (
          typeof cellValue === 'string' &&
          cellValue.includes('{"blocks":') &&
          cellValue.includes('"text":')
        ) {
          cellValue = parseDescriptionText(cellValue);
        }
  
        acc.push(cellValue);
        return acc;
      }, [])
    );
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${pageTitle}.xlsx`);
  };
  const handleCopyToClipboard = () => {
    const headers = table
      .getHeaderGroups()
      .flatMap((headerGroup) =>
        headerGroup.headers.map((header) => header.column.columnDef.header || header.id)
        .filter((header) => header.toLowerCase() !== 'action')
      );
  
    const rows = table.getRowModel().rows.map((row) =>
      row.getVisibleCells().reduce((acc, cell) => {
        if (cell.column.id === 'action') {
          return acc;
        }
  
        let cellValue = cell.getValue();
  
        if (cell.column.id === 'is_active') {
          cellValue = cellValue ? 'Active' : 'Inactive';
        }
        if (cell.column.id === 'image') {
          cellValue = cellValue ? `Image URL: ${cellValue}` : '';
        } else if (
          typeof cellValue === 'string' &&
          cellValue.includes('{"blocks":') &&
          cellValue.includes('"text":')
        ) {
          cellValue = parseDescriptionText(cellValue);
        }
  
        acc.push(cellValue);
        return acc;
      }, [])
    );
    const textToCopy = [headers.join("\t"), ...rows.map(row => row.join("\t"))].join("\n");
    navigator.clipboard.writeText(textToCopy).then(() => {
    }).catch((err) => {
      console.error("Failed to copy data:", err);
    });
  };
  
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalPages = table.getPageCount()>0?table.getPageCount():1;
  
  const renderPageItems = () => {
    const pagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
  
    if (endPage - startPage + 1 < pagesToShow) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }
  
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => table.setPageIndex(i - 1)} className="paginate_button">
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
  
    return pages;
  };
  return (
    <div className="table-container" style={{ overflowX: 'auto' }}>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="dt-buttons">
          <Button className="btn btn-primary stylehundredone" type="button" onClick={handleCopyToClipboard}>
            {t("copy")}
          </Button>
          <Button className="btn btn-primary stylehundredone" type="button" onClick={handleDownloadCSV}>
            CSV
          </Button>
          <Button className="btn btn-primary stylehundredone" type="button"  onClick={handleDownloadExcel}>
            Excel
          </Button>
          <Button className="btn btn-primary stylehundredone" type="button" onClick={handleDownloadPDF}>
            PDF
          </Button>
          <Button
            className="btn btn-primary stylehundredone btn-style-mobile"
            type="button"
            onClick={handlePrintTable}
          >
            {t("print")}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
         <div id="manage_filter" className="dataTables_filter">
              <Label>
                {t("search")}:
                <input type="search" className="" placeholder="" aria-controls="manage" value={searchTerm}
                  onChange={handleSearchChange} />
              </Label>
            </div>
        </div>
      </div>

      <div
        ref={tableRef}
        id="table-container"
        className="table-container"
        style={{ overflowX: 'auto' }}
      >
        <Table 
        style={{ width: '100%', borderCollapse: 'collapse' }}
        className="table table-bordered table-hover toggle-circle custom-table"
        id="manage"
        role="grid"
        aria-describedby="manage_info"
        >
          {table.getHeaderGroups().some(headerGroup =>
              headerGroup.headers.some(header => header.id === "total_credits" || header.id === 'total_credit')
            ) && (
              <caption>
                <p>{t("totalCreditsCalc")}</p>
              </caption>
            )}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} role="row">
                {headerGroup.headers 
                .map((header) => {
                  return (
                    <th
                      key={header.id}
                      className="sorting_asc"
                      aria-controls="manage"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="ID: activate to sort column descending"
                      style={{
                        cursor: header.column.getCanSort() && !["serial", "actions"].includes(header.column.id) ? "pointer" : "default",
                      }}
                      onClick={
                        header.column.getCanSort() && !["serial", "actions"].includes(header.column.id)
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort()&& !["serial", "actions"].includes(header.column.id) &&  (
                        <span>
                        &nbsp;
                        {header.column.getIsSorted() === 'asc' ? (
                          <BiCaretUp />
                        ) : header.column.getIsSorted() === 'desc' ? (
                          <BiCaretDown />
                        ) : (
                          <PiCaretUpDownFill />
                        )}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              table.getRowModel().rows?.length>0?<>
                {table.getRowModel().rows.map((row) => (
              <tr  role="row" key={row.id} data-row-index={row.index}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      data-cell-id={cell.id}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
              </>:<tr> <td colSpan={11} className='text-center'>{t("noDataFound")}</td></tr>
            }
          </tbody>
        </Table>
      </div>
      <div className="align-item-end pagination-style-mobile">
        <div
          className="dataTables_info"
          id="manage_info"
          role="status"
          aria-live="polite"
        >
          <p>{t("showing")}: {table.getPageCount()>0?currentPage:"0"} of {table.getPageCount()} {t("entries")}</p>
        </div>
        <div className="dataTables_paginate paging_simple_numbers">
        <Pagination aria-label="Page navigation example" size="md">
            <PaginationItem disabled={!table.getCanPreviousPage()}>
              <PaginationLink onClick={() => table.previousPage()} className="paginate_button previous">
                {t("previous")}
              </PaginationLink>
            </PaginationItem>
              {renderPageItems()}
            <PaginationItem disabled={!table.getCanNextPage()}>
              <PaginationLink onClick={() => table.nextPage()} className="paginate_button next ">
                {t("next")}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CommonDataTable);
