import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Initialize i18next
i18n
  .use(Backend) // Load translations from files
  .use(initReactI18next) // Connect i18next with React
  .init({
    fallbackLng: 'en', // Fallback language if detection fails
    debug: true, // Enable debugging for development

    interpolation: {
      escapeValue: false, // React already escapes values
    },
    react: {
      useSuspense: false, // Disable Suspense for easier setup (optional)
    },
    backend: {
      loadPath: '/locale/{{lng}}/translation.json', // Path where translation files are stored
    },
    detection: {
      // Configure language detection behavior
      order: ['localStorage', 'navigator'], // Customize the detection order
      caches: ['localStorage'], // Cache detected language in localStorage
      lookupQuerystring: 'lang', // Look for the language parameter in the query string
      lookupLocalStorage: 'i18nextLng', // Look for the language in localStorage
      lookupCookie: 'i18next', // Look for the language in cookies
      // Remove the region part of the language, e.g. "en-US" -> "en"
      lookupFromPathIndex: 0, // This will limit language to the first part (like 'en' from 'en-US')
    },
  });

export default i18n;
