import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiCaretDown, BiCaretUp, BiCheck } from "react-icons/bi";
import { FaPencilAlt, FaFilter, FaPlus } from "react-icons/fa";
import { MdFilterList } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Table,
} from "reactstrap";
import {
  multipartPatchCallWithErrorResponseAxios,
  simpleGetCallWithTokenAxios,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import ToastMsg from "../../../utils/ToastMsg";
import CommonDataTable from "../../../commonComponents/CommonDataTable";
/* eslint-disable camelcase */
const OfferedCoursesBySession = (args) => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("S£"); // Default sorting by university
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting in ascending order
  // Function to toggle sorting order
  const toggleSortOrder = (columnName) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };
  const [modal, setModal] = useState(false);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [registeredData, setRegisteredData] = useState([]);
  const [courseProgramStatuses, setCourseProgramStatuses] = useState([]);
  const [teacherName, setTeacherName] = useState([]);
  const [assistantTeacherName, setAssistantTeacherName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({ program: "", session: "" });
  const [courseProgramNames, setCourseProgramNames] = useState({});
  const [courseSessionNames, setCourseSessionNames] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editProgramCourseStatusData, setEditProgramCourseStatusData] =
    useState({
      id: null,
      academic_session: "",
      program_course: "",
      program_course_name: "",
      academic_session_name: "",
      teacher_name: "",
      assistant_teacher_name: "",
      semester_number: "",
      semester_section: "",
      description: "",
      campus_intake: "",
      allowed_registrant: "",
      is_parent_course: false,
    });
  const navigate = useNavigate();
  // API section start
  const getOfferedCourseBySession = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.OFFERED_COURSE)
      .then((res) => {
        const CourseTypes = res?.data?.results?.reduce((acc, CourseType) => {
          if (!Object.values(acc).includes(CourseType.program_name)) {
            acc[CourseType.program] = CourseType.program_name;
          }
          return acc;
        }, {});
        setCourseProgramNames(CourseTypes);
        const CourseSession = res?.data?.results?.reduce((acc, CourseType) => {
          if (!Object.values(acc).includes(CourseType.academic_session_name)) {
            acc[CourseType.academic_session] = CourseType.academic_session_name;
          }
          return acc;
        }, {});

        setCourseSessionNames(CourseSession);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const sortProgramCourseStatuses = (statuses) => {
    
    if (!Array.isArray(statuses)) {
      return [statuses];
    }
    const sortedStatuses = [...statuses].sort((a, b) => {
      const getFieldValue = (status, field) => {
        switch (field) {
          case "Program":
            return status?.program_name?.toUpperCase() ?? "";
          case "Course":
            return status?.program_course_name?.toUpperCase() ?? "";
          case "Academic Session":
            return status?.academic_session_name?.toUpperCase() ?? "";
            case "Teacher":
              return status?.teacher_name?.toUpperCase() ?? "";  
          case "Students":
            return Number(status?.registered_students) || 0;;
          default:
            return "";
        }
      };

      const valueA = getFieldValue(a, sortBy);
      const valueB = getFieldValue(b, sortBy);

      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
      return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
    });

    return sortedStatuses;
  };
  const getTeacherList = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.TEACHERS_LIST)
      .then((res) => {
        const teacherList = res?.data?.reduce((acc, teacher) => {
          acc[teacher?.id] = `${teacher?.first_name} ${teacher?.last_name}`;
          return acc;
        }, {});
        setTeacherName(teacherList);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getAssistantTeacherList = () => {
    setLoading(true);
    simpleGetCallWithTokenAxios(ApiConfig.ASSISTANT_TEACHERS_LIST)
      .then((res) => {
        const assistantTeacherData = res?.data?.reduce((acc, teacher) => {
          acc[teacher?.id] = `${teacher?.first_name} ${teacher?.last_name}`;
          return acc;
        }, {});
        setAssistantTeacherName(assistantTeacherData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getData = (filter) => {
    const cleanedFilter = Object.fromEntries(
      Object.entries(filter).filter(([_, value]) => value !== 'all')
    );
  
    if (Object.keys(cleanedFilter).length > 0) {
      const queryParams = new URLSearchParams();
      if (cleanedFilter.program) queryParams.append("program_course__program", cleanedFilter.program);
      if (cleanedFilter.session) queryParams.append("academic_session", cleanedFilter.session);
  
      const url = `${ApiConfig.OFFERED_COURSE}?${queryParams.toString()}`;
      simpleGetCallWithTokenAxios(url)
        .then((res) => {
          const sortedStatuses = sortProgramCourseStatuses(res?.data?.results);
          setCourseProgramStatuses(sortedStatuses);
          setFilteredStatuses(sortedStatuses);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      simpleGetCallWithTokenAxios(ApiConfig.OFFERED_COURSE)
        .then((res) => {
          const sortedStatuses = sortProgramCourseStatuses(res?.data?.results);
          setCourseProgramStatuses(sortedStatuses);
          setFilteredStatuses(sortedStatuses);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  const getRegisteredData = (id) => {
    if (id) {
      const url = `${ApiConfig.REGISTERED_COURSE}?offered_course=${id}`;
      simpleGetCallWithTokenAxios(url)
        .then((res) => {
          setRegisteredData(res?.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
const Toggle = (value) => {
    setModal((prev) => {
        if (!prev && value) {
            getRegisteredData(value);
        }
        return !prev;
    });
};
  useEffect(() => {
    getData(false);
    getOfferedCourseBySession();
    getTeacherList();
    getAssistantTeacherList();
  }, []);
  const handleFilter = (event) => {
    event.preventDefault();
    if (filterData.program || filterData.session) {
      getData(filterData);
    }
  };
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value) {
      const searchTerms = value
        .toLowerCase()
        .split(",")
        .map((term) => term.trim());
      const nameFilter = searchTerms[0];
      const filtered = courseProgramStatuses.filter((status) => {
        let nameMatch = true;
        if (nameFilter) {
          nameMatch = status?.program_name.toLowerCase().includes(nameFilter);
        }
        return nameMatch;
      });
      setFilteredStatuses(filtered);
    } else {
      setFilteredStatuses(courseProgramStatuses);
    }
  };
  const editToggle = (status) => {
    setEditModal(!editModal);
    if (status) {
      setEditProgramCourseStatusData({
        id: status?.id,
        academic_session: status?.academic_session,
        program_course: status?.program_course,
        program_course_name: status?.program_course_name,
        academic_session_name: status?.academic_session_name,
        teacher_name: status?.teacher_name,
        assistant_teacher_name: status?.assistant_teacher_name,
        semester_number: status?.semester_number,
        semester_section: status?.semester_section,
        description: status?.description,
        campus_intake: status?.campus_intake,
        allowed_registrant: status?.allowed_registrant,
        is_parent_course: status.is_parent_course,
      });
    }
  };
  const editProgramSessionStatus = () => {
    const body = {
      campus_intake: editProgramCourseStatusData?.campus_intake,
      teacher: editProgramCourseStatusData?.teacher,
      assistant_teacher: editProgramCourseStatusData?.assistant_teacher,
      program_course: editProgramCourseStatusData?.program_course,
      academic_session: editProgramCourseStatusData?.academic_session,
      semester_number: editProgramCourseStatusData?.semester_number,
      semester_section: editProgramCourseStatusData?.semester_section,
      description: editProgramCourseStatusData?.description,
      allowed_registrant: editProgramCourseStatusData?.allowed_registrant,
      is_parent_course: editProgramCourseStatusData?.is_parent_course,
    };
    multipartPatchCallWithErrorResponseAxios(
      `${ApiConfig.OFFERED_COURSE}${editProgramCourseStatusData.id}/`,
      body
    )
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          editToggle();
          ToastMsg("success", t("successUpdatedData"));
          // getOfferedCourseBySession();
          getData(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        ToastMsg("error", err);
        setLoading(false);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (editModal) {
      editProgramSessionStatus();
    }
  };
  const memoizedData = React.useMemo(() => filteredStatuses, [filteredStatuses]);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'serial',
        header: t("no"),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'program_name',
        header: t("program"),
      },
      {
        accessorKey: 'program_course_name',
        header: t("course"),
      },
      {
        accessorKey: 'academic_session_name',
        header: t("academicSession")
      },
      {
        accessorKey: 'teacher_name',
        header: t("teacher"),
      },
      {
        accessorKey: 'actions',
        header: t("actions"),
        meta: { isToggleSwitch: true },
        cell: ({ row }) => {
          return (
            <div className="text-nowrap col-1">
            <a
              data-toggle="modal"
              data-target="#responsive-modal-edit"
              data-original-title="Edit"
            >
              <i className="fa fa-pencil text-inverse m-r-10 fa-2x hoverPointer">
                <FaPencilAlt
                  style={{ color: '#faab7a', width: 18, height: 18 }}
                  onClick={() => editToggle(row.original)}
                />
              </i>
            </a>
          </div>
          );
        },
      },
      {
        accessorKey: 'registered_students',
        header: t("students"),
        meta: { isToggleSwitch: true },
        cell: ({ row }) => {
          const { id, is_active } = row.original;

          return (
            <>
             <Button
              className="btn btn-info btn-circle m-r-10"
              title="Show students list"
              data-toggle="modal"
              data-target="#responsive-modal-list"
              data-bs-original-title="Show List"
              onClick={() =>
                Toggle(id)
              }
            >
              {row.original?.registered_students}
            </Button>
            <Button
              className="btn btn-danger"
              title="Add campus intake"
              onClick={() => navigate("/StdCourseReg")}
            >
              <FaPlus className="fa fa-plus" />
            </Button>
            </>
          );
        },
      },
    ],
    [],
  );  
  return (
    <>
      <div className="page-wrapper">
        <div className="card">
          <div className="card-header bg-info">
            <h4 className="m-b-0 text-white">{t("offeredCoursesBySession")}</h4>
          </div>
          <div className="card-body">
            <div className="card col-12  center  border p-5">
              <h4 className="card-title">
                <MdFilterList className=" mdi mdi-filter-variant" /> {t("filter")}
                {t("results")}
              </h4>
              <div id="education_fields"></div>
              <div className="row">
                <div className="col-sm-12">
                  <Form className="form-horizontal " onSubmit={handleFilter}>
                    <div className="form-group validate">
                      <Label className="control-label">{t("academicSession")}</Label>
                      <select
                        className="form-select form-control select2"
                        id="academic-session"
                        name="academic-session"
                        value={filterData.session}
                        onChange={(e) =>
                          setFilterData((prevState) => ({
                            ...prevState,
                            session: e.target.value,
                          }))
                        }
                      >
                        <option value="" disabled selected>
                          ---{t("selectAll")}---
                        </option>
                        <option value="all">{t("all")}</option>
                        {Object.entries(courseSessionNames).map(
                          ([id, name]) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="form-group validate">
                      <Label className="control-label">Select Program:</Label>
                      <select
                        className="form-select form-control select2"
                        id="program"
                        name="program"
                        aria-invalid="false"
                        value={filterData.program}
                        onChange={(e) =>
                          setFilterData((prevState) => ({
                            ...prevState,
                            program: e.target.value,
                          }))
                        }
                      >
                        <option value="" disabled selected>
                          ---{t("selectAll")}---
                        </option>
                        <option value="all">{t("all")}</option>
                        {Object.entries(courseProgramNames).map(
                          ([id, name]) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-info waves-effect waves-light"
                    >
                      <FaFilter className="fa fa-filter" /> {t("filter")}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <CommonDataTable columns={columns} data={memoizedData} />
          </div>
        </div>
        <Modal
          isOpen={modal}
          addModuleForm={Toggle}
          {...args}
          className="modal-dialog"
          style={{maxWidth:"800px"}}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Registered Students</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={Toggle}
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="m-t-40">
                <div className="form-body">
                  <div className="form-group">
                    <div className="controls">
                      <div className="table-responsive">
                        <Table className="display nowrap table table-hover table-striped table-bordered">
                          <thead>
                            <tr role="row">
                              <th>ID</th>
                              <th>{t("batch")}</th>
                              <th>{t("student")}</th>
                              <th>Offered Course</th>
                              <th>Registration Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            { registeredData && registeredData?.length>0?
                              registeredData?.map((item,i)=>{
                                return <tr key={item?.id}>
                                <th>
                                  {i+1}
                                </th>
                                <td>{item?.offered_course?.academic_session_name}</td>
                                {/* <td>BS/CS/F22/001</td> */}
                                <td>{`${item?.student?.user?.first_name} ${item?.student?.user?.last_name}`}</td>
                                <td>{item?.offered_course?.program_course_name}</td>
                                <td>{item?.registration_status?.is_enrolled?'Enrolled':'Drop'}</td>
                              </tr>
                              }): <tr>
                              <td colSpan={8} className="text-center">
                                {t("noDataFound")}
                              </td>
                            </tr>
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="help-block"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
                onClick={Toggle}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        isOpen={editModal}
        addModuleForm={editToggle}
        {...args}
        className="modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Edit Offered Course</h4>
            <Button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={editToggle}
            >
              ×
            </Button>
          </div>
          <div className="modal-body">
            <Form className="m-t-40" onSubmit={handleSubmit}>
              <div className="form-body">
                <div className="form-group">
                  <Label className="control-label">
                    Course Code:{" "}
                    {editProgramCourseStatusData?.program_course_name}
                  </Label>
                </div>
                <div className="form-group">
                  <Label className="control-label">
                    {t("academicSession")}:{" "}
                    {editProgramCourseStatusData?.academic_session_name}
                  </Label>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Teacher</Label>
                  <select
                    className="form-select form-control select2"
                    id="teacher_name"
                    name="teacher_name"
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData?.teacher_name}
                    onChange={(e) =>
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        teacher: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(teacherName).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group validate">
                  <Label className="control-label">Assistant Teacher</Label>
                  <select
                    className="form-select form-control select2"
                    id="assistant_teacher_name"
                    name="assistant_teacher_name"
                    required
                    aria-invalid="false"
                    value={editProgramCourseStatusData.assistant_teacher_name}
                    onChange={(e) =>
                      setEditProgramCourseStatusData({
                        ...editProgramCourseStatusData,
                        assistant_teacher: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled selected>
                      ---Select---
                    </option>
                    {Object.entries(assistantTeacherName).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <Label className="control-label">Semester Number</Label>
                  <div className="controls">
                    <input
                      type="text"
                      id="semester_number"
                      className="form-control"
                      required
                      aria-invalid="false"
                      placeholder="Semester Number"
                      value={editProgramCourseStatusData.semester_number}
                      onChange={(e) =>
                        setEditProgramCourseStatusData({
                          ...editProgramCourseStatusData,
                          semester_number: e.target.value,
                        })
                      }
                    />
                    <div className="help-block"></div>
                  </div>
                </div>
                <div className="form-group">
                  <Label className="control-label">Semester Section</Label>
                  <div className="controls">
                    <input
                      type="text"
                      id="semester_section"
                      className="form-control"
                      required
                      aria-invalid="false"
                      placeholder="Semester Section"
                      value={editProgramCourseStatusData.semester_section}
                      onChange={(e) =>
                        setEditProgramCourseStatusData({
                          ...editProgramCourseStatusData,
                          semester_section: e.target.value,
                        })
                      }
                    />
                    <div className="help-block"></div>
                  </div>
                </div>
                <div className="form-group">
                  <Label className="control-label">{t("description")}</Label>
                  <div className="controls">
                    <textarea
                      id="description"
                      className="form-control"
                      rows={3}
                      placeholder={t("enterDescription")}
                      aria-invalid="false"
                      value={editProgramCourseStatusData.description}
                      onChange={(e) =>
                        setEditProgramCourseStatusData({
                          ...editProgramCourseStatusData,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                    <div className="help-block"></div>
                  </div>
                </div>
                <div className="form-group">
                  <Label className="control-label">Allowed Registrants</Label>
                  <div className="controls">
                    <input
                      type="number"
                      id="allowed_registrant"
                      className="form-control"
                      required
                      aria-invalid="false"
                      placeholder="Allowed Registrant"
                      value={editProgramCourseStatusData.allowed_registrant}
                      onChange={(e) =>
                        setEditProgramCourseStatusData({
                          ...editProgramCourseStatusData,
                          allowed_registrant: e.target.value,
                        })
                      }
                    />
                    <div className="help-block"></div>
                  </div>
                </div>
                <div className="form-group">
                  <Label className="control-label">Is Parent Course</Label>
                  <div className="controls">
                    <FormGroup check>
                      <Label check className="custom-control-label">
                        <Input
                          type="checkbox"
                          name="is_parent_course"
                          checked={editProgramCourseStatusData.is_parent_course}
                          onChange={(e) =>
                            setEditProgramCourseStatusData({
                              ...editProgramCourseStatusData,
                              is_parent_course: e.target.checked,
                            })
                          }
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <div className="help-block"></div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="button"
                  className="btn btn-default waves-effect"
                  data-dismiss="modal"
                  onClick={editToggle}
                >
                  {t("close")}
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary stylehundredone"
                >
                  {t("saveChanges")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default OfferedCoursesBySession;
